import { useEffect, useRef, useState } from 'react';
import { CardHead } from '../ui/index'
import { useDispatch, useSelector } from 'react-redux';
import { addClientInfo, resetClientInfo } from '../../store/clientInfo/ClientInfoSlices';
import {  OrderList } from '../order';
import { useGeneral } from '../../context';



export const PerfilClient = ({ closeSection }) => {

    const clientInfo = useSelector(state => state.clientInfo)
    const clients = useSelector(state => state.clients)
    const fullOrd = useSelector((state) => state.orders)

    const clientRef = useRef(null)

    const [loading, setLoading] = useState(true)
    const [lastOrders, setLastOrders] = useState([])

    const { convertToDate } = useGeneral()
    const dispatch = useDispatch()

    //funcion para formatear a pesos arg (formatter.format(num))
    const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
    });


    /* lista de datos del cliente para el encabezado del perfil cliente*/
    const clInfo = [
        { id: "Cuit", value: clientInfo.CUIT },
        { id: 'ID', value: clientInfo.Cliente_ID },
        { id: "Pago", value: clientInfo.PymntGroup || '-' },
        { id: "ID Ventas", value: clientInfo.ID_Dpto_Ventas || '-' },
        { id: "Mup %", value: clientInfo.MUp ? parseInt(((100 / (100 - clientInfo.MUp)) - 1) * 100) : '-' },
        { id: "Mup % (LB)", value: clientInfo.MUp_LineaB ? parseInt(((100 / (100 - clientInfo.MUp_LineaB)) - 1) * 100) : '-' },
        { id: 'Limite Credito', value: formatter.format(clientInfo.Limite_Credito) },
        { id: 'DebtLine', value: formatter.format(clientInfo.DebtLine) },
        { id: 'IVA', value: clientInfo.Iva },
    ]
    const clTurno = [
        { id: 'Turno', value: clientInfo.Turno },
        { id: 'Contacto Turno', value: clientInfo.Contacto_Turno },
    ]
    const clDir = [
        { id: "Facturacion", value: clientInfo.Direccion_Facturacion ? `${clientInfo.Direccion_Facturacion},\n${clientInfo.Provincia_Facturacion}, (${clientInfo.Cod__Postal_Factu || ''})` : '-' },
        { id: "Entrega", value: clientInfo.Direccion_Entrega ? `${clientInfo.Direccion_Entrega},\n${clientInfo.Provincia_Entrega}, (${clientInfo.Cod__Postal_Entrega || ''})` : '-' },
    ]


    const filterLastOrders = () => {
        return fullOrd.filter((o) => o.CardCode === clientInfo.Cliente_ID)
    }

    // const sortListBy = (list, field) => {
    //     console.log(field)
    //     return list.sort((a, b) => a.field - b.field).reverse()

    // }


    const sortListBy = (list) => {

        return list.sort((a, b) => {
            const dateA = convertToDate(a.Fecha);
            const dateB = convertToDate(b.Fecha);

            return dateB - dateA; // Esto ordenará de más reciente a más antigua
        });
    }

    useEffect(() => {

        let lastO = filterLastOrders()

        setLastOrders(
            sortListBy(lastO, 'Fecha')
                .slice(0, 5)
        )

        return () => {
            setLastOrders([])
        }
    }, [fullOrd, clientInfo])

    useEffect(() => {
        dispatch(resetClientInfo())
        let stringUrl = window.location.toString()
        let cliNum = stringUrl.split('=').pop()

        dispatch(addClientInfo(clients.find(obj => obj.Cliente_ID === cliNum)))

        setLoading(false)

    }, [clients])



    return (
        <>
            {
                !loading
                &&
                <div className="card heightFitCont" ref={clientRef}>
                    <CardHead section={'CLIENTE'} clientInfo={clientInfo} closeSection={closeSection} />

                    {
                        clientInfo.Inactivo === 'Y'
                        &&
                        <h4 className='clrBgPrimary textWhite fw-bold text-center mb-0'> INACTIVO</h4>
                    }

                    <div className='fontSizeSmallSM mx-auto w-75 w-100SM  d-flex flex-column flex-wrap gap-3 justify-content-center'>
                        {/* mapeo que muestra los datos del encabezado */}
                        <div className='py-2 px-1 d-flex justify-content-center flex-wrap gap-3 clrBgTableHeadLight rounded-bottom' >
                            {
                                clInfo.map((info) => (
                                    <div key={info.id} className="text-center cPerfdatoContainer d-flex flex-column ">
                                        <div className='text-center fw-bold'>
                                            {info.id}
                                        </div>

                                        <span className='' id={"clID" + info.id}>
                                            {info.value}
                                        </span>

                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className={`d-flex mx-auto border-bottom gap-4 p-3 gap0SM fontSizeSmallSM flexColumnSM ${clTurno[1].value ? 'flex-column' : ''}`}>
                        <div>
                            <span className='me-2 fw-bold'>{clTurno[0].id}</span>
                            <span>{clTurno[0].value}</span>
                        </div>

                        <div className={`d-flex ${clientInfo.Contacto_Turno ? 'flexColumnSM' : ''}`}>
                            <span className='me-2 fw-bold'>{clTurno[1].id}</span>
                            <span>{clTurno[1].value || '-'}</span>
                        </div>
                    </div>

                    <div className='row px-3 gx-0 px0SM'>
                        {
                            clDir.map((info) => (
                                <div key={info.id} className="fontSizeSmallSM col-sm-6 col-12 cPerfdatoContainer  mt-3 d-flex flex-column px-2">
                                    <div className='fw-bold'>
                                        {info.id}
                                    </div>

                                    <ul>
                                        <li className='mt-2' id={"clID" + info.id}>
                                            {info.value}
                                        </li>
 {/* TODO DIRECCIONES */}
                                        {
                                            info.id === 'Entrega' && clientInfo.Direcciones_Extra
                                            && clientInfo.Direcciones_Extra.map(di => (
                                                <li className='mt-2 ' key={clientInfo.Direcciones_Extra.indexOf(di)}>
                                                    {di.Calle || ''} &nbsp;
                                                    {di.Número_de_la_calle || ''},&nbsp;
                                                    {di.Ciudad || ''}, &nbsp;
                                                    {di.Código_postal ? '(' + di.Código_postal + ')' : ''}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            ))
                        }
                    </div>
                    <div className='border-top pt-4'>
                        <h4 className='fw-bold ms-2'>Ultimos Pedidos</h4>
                        <OrderList orderList={lastOrders} pagination={false} />
                    </div>
                </div>
            }
        </>)
}
