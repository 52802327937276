import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Adddata, db } from '../../data/index';
import { addUser } from '../../store/userdata/UserSlices';
import { CardHead } from '../ui/index'
import { doc } from 'firebase/firestore';

export const EditperfilUser = ({ closeSection }) => {

    const userData = useSelector(state => state.user)

    const dispatch = useDispatch()

    // //estado con los datos de edit perfil
    const [editData, setEditData] = useState({});
    const [changed, setChanged] = useState(false)

    /* funcion que pasa de mi cuenta a editar datos */
    const saveData = () => {
        try {
            const docRef = doc(db, 'Usuarios', userData.UID)

            Adddata(docRef, editData)
        } catch {
            alert("No se guardaron cambios")
        } finally {
            closeSection()
        }
    };

    //set de datos  del estado para subir a la base
    const handleInfoChange = ({ target: { name, value } }) => {
        setChanged(true)
        dispatch(addUser({ ...userData, [name]: value }))
        setEditData({ ...editData, [name]: value });
    }


    return (

        <div className="modalBase">
            <div className="clrBgWhite width38Modal mt-a maxH100 overflow-auto">

                <CardHead section={'EDITAR DATOS'} modal={true} closeSection={closeSection} />

                <form className="form-group col-sm-8 col-lg-6 col-md-7 m-auto mt-4 w-75">
                    <h1 className='text-uppercase fw-bold'>{userData.Apellido}</h1>
                    <h3 className='text-capitalize fw-bold'>{userData.Nombre}</h3>
                    <br />
                    <label htmlFor='telEdit' className='text-muted'>
                        Telefono:
                    </label>
                    <input
                        id='telEdit'
                        className="form-control inputs textColorGreyM"
                        type="text"
                        inputMode='numeric'
                        name="Telefono"
                        pattern='[0-9]'
                        onChange={handleInfoChange}
                        placeholder="Telefono"
                        value={userData.Telefono}
                    />

                    <br />
                    <label htmlFor='cuitEdit' className='text-muted'>
                        Cuit:
                    </label>
                    <input
                        id='cuitEdit'
                        className="form-control inputs mb-4 textColorGrey"
                        type="text"
                        pattern='[0-9]'
                        inputMode='numeric'
                        maxLength="10"
                        name="Cuit"
                        onChange={handleInfoChange}
                        placeholder="Cuit"
                        value={userData.Cuit}
                    />

                    <button
                        type="button"
                        className='btn btn-outline btnBlue float-end px-5 mt-3 mb-4'
                        onClick={saveData}
                        disabled={!changed}
                    > Guardar
                    </button>

                    <br />
                </form>
            </div>
        </div>
    )
}