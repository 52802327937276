import { useSelector } from 'react-redux'
import { CardHead, Loading } from '../ui/index'
import { TableTks } from '../tools/TableTks';


export const Precios = () => {
   
    const pricedata = useSelector(state => state.price)

    return (
        <div className="card maxHInherit">

            <CardHead section={'PRECIOS'} searchBar={true}/>

            {
                pricedata && pricedata.length > 0
                    ? <TableTks section = {'PRECIOS'} />
                    : <Loading />
            }

        </div>
    )
}
