import React from 'react'

export const Skeleton = () => {
  return (

        <div className='progress-infinite'>

        </div>

  )
}
