import packageJson from '../../../package.json';
import {  useSelector } from 'react-redux';

export const Footer = () => {
    const testIndicator = useSelector((state) => state.testIndicator)

    const checkBases = () => {
        let classname = ''

        if (!Object.values(testIndicator).includes(false)) {
            classname = 'clrBgAccept'
        } else if (
            Object.values(testIndicator).includes(true)
            && Object.values(testIndicator).includes(false)
        ) {
            classname = 'clrBgDecline'
        }
        return classname
    }

    return (
        <footer className={`textColorGreyL text-center pt-2 fontSize11 position-absolute w-100 footer ${checkBases()}`}>
            <span className=''>
                &copy; Goldmund&reg; | Todos los Derechos reservados.
            </span>
            <span> v{packageJson.version} </span>
        </footer>
    )
}


















// <!--------------------------
// . _______   _    _    _____
// .|__   __|.| |./ /../  __ /..
// ....| |....| |/ /...| <__....
// ....| |....|  _ \...\___ \...
// ....| |....| |.\ \...___> |..
// ....|_|....|_|..\_\./____/...
// .............................
// ----------------------------->