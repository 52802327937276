import {createSlice} from '@reduxjs/toolkit'

export const PriceSlices = createSlice({
    name: 'price',
    initialState: [],
    reducers: {
        addPrice: (state, action) => {
            return action.payload
        },
    }
})

export const {addPrice} = PriceSlices.actions
export default PriceSlices.reducer