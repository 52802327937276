import React, { useState } from 'react'
import { Order } from './index'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addOrderData } from "../../store/orderData/OrderDataSlices"
import { modalOpen } from "../../store/openModal/OpenModalSlices"
import { addFilter } from "../../store/searchFilter/SearchFilterSlices"


const ItemsPerPage = 15;

export const OrderList = ({ orderList, pagination }) => {

    const dispatch = useDispatch()

    // filtro del buscador
    const filter = useSelector(state => state.searchFilter)

    //paginacion
    const [currentPage, setCurrentPage] = useState(1)
    // Calcular el índice de inicio y final para la página actual
    const startIndex = (currentPage - 1) * ItemsPerPage;
    const endIndex = startIndex + ItemsPerPage;
    // Obtener los elementos que se mostrarán en la página actual
    const itemsToDisplay = orderList.slice(startIndex, endIndex);

    // Función para cambiar de página
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleOrderClick = (order) => {

        dispatch(addOrderData(order))

        if (order.Estado === 'Preliminar') {
            dispatch(modalOpen({ a: true }))
        }

        if (filter !== '') {
            dispatch(addFilter(''))
        }
    }

    const totalPages = (items) => {

        return Math.ceil(items.length / ItemsPerPage)
    }

    return (
        <>
        
            <ol className="px-0" >
                {
                    itemsToDisplay
                        .map((order) => (
                            <NavLink
                                key={order.Num_pedido + itemsToDisplay.indexOf(order)}
                                to={order.Estado !== 'Preliminar' ? `/sections/orders/orderinfo?order=${order.Num_pedido}` : ''}
                                onClick={() => handleOrderClick(order)}
                                className="text-decoration-none textColorGreyM fw-bold"
                            >
                                <Order
                                    order={order}
                                />
                            </NavLink>
                        ))
                }
            </ol>

            {
                pagination
                &&
                <div className='d-flex justify-content-center'>
                    <button
                        className='btn btn-outline btnBlue mx-1'
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                    >
                        &#8592;
                    </button>
                    <span className='align-self-center mx-2 textColorGrey small'>
                        {' '}

                        {currentPage} de {totalPages(orderList)}
                        {' '}
                    </span>

                    <button
                        className='btn btn-outline btnBlue mx-1'
                        disabled={endIndex >= orderList.length}
                        onClick={() => handlePageChange(currentPage + 1)}
                    >
                        &#8594;
                    </button>
                </div>
            }
        </>
    )
}
