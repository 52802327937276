
import { EditperfilUser, PerfilUser } from './index'
import { CardHead } from '../ui/index'
import {  useSelector } from 'react-redux'

export const User = ({ closeSection }) => {
    const isOpenModal = useSelector((state) => state.openModal)

    return (
        <div className="card ">
            <CardHead section={"MI CUENTA"} />

            <PerfilUser />
            {
                isOpenModal.a &&
                <EditperfilUser closeSection={closeSection} />
            }
        </div>
    )
};
