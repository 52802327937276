import {createSlice} from '@reduxjs/toolkit'

export const NewMessageIndiSlices = createSlice({
    name: 'newMessageIndi',
    initialState: false,
    reducers: {
        addNewMessageIndi: (state, action) => {
            return action.payload
        }
    }
})

export const {addNewMessageIndi} = NewMessageIndiSlices.actions
export default NewMessageIndiSlices.reducer