import { useSelector } from 'react-redux'
import editIco from '../../assets/images/editIco.png'


export const List = ({openDetail, hadleDetail, handleModalUser}) => {

    const userList = useSelector((state) => state.userList)
    //console.log(userList)


    const renderNombreCompletoGerencia = (u) => {
        if (u.Permiso === 'Gerencia') {
            const usersWithGerencia = userList.filter(doc => doc.Gerencia === `${u.Nombre} ${u.Apellido}`);
            if (usersWithGerencia.length > 0) {
                return (
                    <>
                      <p>
                        <button class="btn btn-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample">
                            Vendedores a Cargo →
                        </button>
                    </p>
                    <div style={{minHeight: '120px'}}>
                        <div class="collapse collapse-horizontal" id="collapseWidthExample">
                            <div class="card card-body" style={{width: '300px'}}>
                                    <ul>
                                            {usersWithGerencia.map(user => (
                                                <li className="dropdown-item" key={user.UID}>{`${user.Nombre} ${user.Apellido}`}</li>
                                            ))}
                                    </ul>
                            </div>
                        </div>
                    </div>
                       
                    </>

                            // <ul style={{position:'absolute', zIndex:'1050'}} className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2" >
                            //     {usersWithGerencia.map(user => (
                            //         <li className="dropdown-item" key={user.UID}>{`${user.Nombre} ${user.Apellido}`}</li>
                            //     ))}
                            // </ul>
                );
            } else {
                return <span>Ningún usuario tiene esta gerencia</span>;
            }
        }
        return null;
    };

    return (
        <ul className='list-group'>
            {userList.map(u => (
                <li key={u.UID} className='list-group-item px0SM'>

                    <details
                        className='detailsHelp detailsUsers '
                        open={openDetail === u.UID}
                    >
                        <summary
                            id={u.UID}
                            onClick={(e) => hadleDetail(u.UID, e)}
                            className='summaryHelp d-flex '
                        >
                            {u.Apellido} {u.Nombre} ({u.Permiso})
                        </summary>

                        <div className='detailsContent clrBgLightGray p-4 d-flex flex-wrap gap-3 justify-content-between'>

                            {
                                Object.keys(u).sort().map(key => (
                                    !["Nombre", "Apellido"].includes(key)
                                    &&
                                    <div
                                        key={u.Cuit + key}
                                        className='exportData d-flex flex-column position-relative'
                                    >
                                        <span className='small fontSize11 exportOrdLabel'>
                                            {key}
                                        </span>
                                        <span>{u[key].toString()}</span>
                                    </div>
                                ))
                            }

                            <img src={editIco} onClick={(e) => handleModalUser(true, 'mod', u)} alt="editar" title='Modificar' className='cursorPointer icon25px'/>
                        </div>
                                {u.Permiso === 'Gerencia' ? (
                                    <div class="btn-group dropup">
                                        
                                            {renderNombreCompletoGerencia(u)}
                                        </div>
                                        ) : (
                                            <span> - </span>
                                        )}

                    </details>
                </li>
            ))}

        </ul>
    )
}
