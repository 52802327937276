import { useDispatch, useSelector } from 'react-redux'
import { CardHead } from '../ui'
import { Faq, AdminPanel, UserList } from './index'
import { useEffect, useState } from 'react'
import { detailOpen } from '../../store/openModal/OpenDetailSlices'
import { modalOpen } from '../../store/openModal/OpenModalSlices'
import { GroupGeren } from './GroupGeren'
import { CondDePago } from './CondDePago'


export const Administracion = () => {

 
  const permisos = ["Vendedor", "Gerencia", "Direccion", "Administracion", "Sysadmin"]
  const userData = useSelector((state) => state.user)
  const [subSect, setSubSect] = useState('lista')
  const [subSection, setSubSection] = useState('')
  const [modUser, setModUser] = useState({})
  const dispatch = useDispatch()
  
  const handleSubSection = (e) => {
      setSubSect(e.target.value)
  }
  
  const handleModalUser = (bool, sect, u = {}) => {
      setSubSection(sect)
      setModUser(u)
      dispatch(modalOpen({ a: bool }))
  }

  const hadleDetail = (id, e) => {
    e.preventDefault()

    dispatch(detailOpen(id))
  }

 //console.log(subSect)
  return (
      <>  
     <div className="card">
       <CardHead section='Administracion' />
      
    
            <div className="d-flex p-2 " style={{gap:"0.5rem"}}>
                    {
                        ['Administracion', 'Sysadmin', 'Direccion'].includes(userData.Permiso)
                        &&
                        <>
                            <button
                                className={`p-2 btn checkBtn text-uppercase ${subSect === 'lista' ? 'checkedBtn' : ''}`}
                                value='lista'
                                onClick={handleSubSection}
                            >
                                Lista de Usuarios
                            </button>

                        </>
                    }
                    {
                        ['Administracion', 'Sysadmin', 'Direccion','Gerencia'].includes(userData.Permiso)
                        && 
                        <button 
                            className={`p-2 btn checkBtn text-uppercase ${subSect === 'gerencia' ? 'checkedBtn' : ''}`}
                            value='gerencia'
                            onClick={handleSubSection}
                            >
                              Asignacion de Clientes
                        </button>
                    }
                    {
                        ['Administracion', 'Sysadmin', 'Direccion'].includes(userData.Permiso)
                        &&
                        <button
                            className={`p-2 btn checkBtn text-uppercase ${subSect === 'pago' ? 'checkedBtn' : ''}`}
                            value='pago'
                            onClick={handleSubSection}>
                            Cond. de Pago
                        </button>
                    }
                
              
            </div>  {
      subSect === 'lista'
                ? <UserList subSection={subSection} permisos={permisos}  hadleDetail={hadleDetail}  handleModalUser={handleModalUser} modUser={modUser} />  
                :  subSect === 'gerencia' ? <GroupGeren /> : <CondDePago/>
      }
</div>
      </>
  )
}
