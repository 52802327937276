import {  useState } from "react";
import {  useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { useAuth } from "../../context/authcontext";
import eyeIco from '../../assets/images/eyeduotone.png';
import peabodyFront from '../../assets/images/peabodyFront.png'
import { auth } from '../../data/index';
import { Alert, ToastWarn, SlackErrorHandle } from '../tools/index'
import { Title } from './index'
import { ToastContainer } from 'react-toastify';


export function Login() {
    //Funcion navigate navega entre rutas
    const navigate = useNavigate();

    const [loadingSign, setLoadingSign] = useState(false)

    // Usestate guarda los datos del formulario de logeo
    const [user, setUser] = useState({ email: "", password: "" });

    // estado para la visibilidad del password 
    const [passwordType, setPasswordType] = useState("password");

    /*estado para mostrar error */
    const [showError, setShowError] = useState(false);

    // referencias a los inputs de mail y password para cambio de clase
    const emailField = document.getElementById("emailField");

    //Llamamos a la funcion de logeo de firebase del context
    const { signin } = useAuth();


    //Extrae los datos del input del formulario
    const handlechange = ({ target: { name, value } }) => {
        setShowError(false)
        setUser({ ...user, [name]: value });
    }


    //Funcion submit del formulario, realiza el logeo y la navegacion a users 
    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            setLoadingSign(true)

            await signin(user.email, user.password);

            //Navegacion luego del logueo
            navigate('/sections/users', {
                replace: true
            });

        } catch (error) {
            setShowError(true)
            setLoadingSign(false)

            console.log(error)
            SlackErrorHandle(`${new Date()}: -> Error en login -> ${error} -> Username: ${user.email}`)

            ToastWarn('Revisar datos de usuario y contraseña')
        }
    };



    //funcion para cambiar visibilidad de la contraseña
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
        } else {
            setPasswordType("password")
        }
    }


    //Funcion que envia mail para resetear la contraseña y muestra un popup con un mensaje
    const triggerResetEmail = async () => {
        let messageOk = "Email enviado, revise su casilla (spam)"
        let messageError = "Dirección de correo no valida"

        if (user.email?.includes('@')) {
            try {
                await sendPasswordResetEmail(auth, user.email);
                alert(messageOk);
            } catch (error) {
                alert(messageError);
                SlackErrorHandle(
                    `${new Date()}: -> Error en envio de reseteo de contraseña ${error} -> Username: ${user.email}`
                )
            }
        } else {
            alert(messageError)
            emailField.focus();
        }
    };


    return (
        <div className="loginContainer m-0 d-flex align-items-center row px-3">
            <div className="col-lg-6 displayNoneLG">
                <img className="imgLogin " alt="imagen de portada" src={peabodyFront} />
            </div>
            <div className="formContainer m-auto clrBgWhite bRadius12 col-sm-12 col-lg-6 p-5 pt0SM">
                <Title />
                <ToastContainer />
                <h2 className='text-center inputs textColorGrey mt-5 mb-3'>Iniciar sesión</h2>
                <div className="alertContainer">
                    {showError && <Alert />}
                </div>
                <form className="form-group m-auto w70MD minW290px">

                    <input
                        className={`form-control inputs mb-4 ${showError ? 'clrBorderRed' : ''}`}
                        name="email"
                        onChange={handlechange}
                        type="email"
                        placeholder="Email de Usuario"
                        id="emailField"
                    />

                    <div className="passwordContainer mb-4 d-flex position-relative">
                        <input
                            className={`form-control inputs ${showError ? 'clrBorderRed' : ''}`}
                            name="password"
                            onChange={handlechange}
                            type={passwordType}
                            placeholder="Contraseña"
                            id="passwordField"
                        />

                        <img
                            className="eyeIcon"
                            onClick={togglePassword}
                            src={eyeIco}
                            alt="o"
                        ></img>

                    </div>

                    <div className="form-check">
                        <a
                            className="float-end fontSize14"
                            href="#" onClick={triggerResetEmail}>
                            ¿Olvidó su contraseña?
                        </a>
                    </div>

                    <div className="d-grid gap-2 pt-5">
                        <button
                            id="submitBtn"
                            onClick={submitHandler}
                            type="submit"
                            className="btn btn-lg btn-block mt-2 mb-1 clrBgPrimary textWhite"
                            disabled={(!user.email.includes("@") || !user.password) || loadingSign || showError}
                        >
                            {
                                !loadingSign &&
                                
                                <span id="submitBtnText" className="">
                                    Iniciar sesión
                                </span>
                            }
                            {
                                loadingSign &&
                                <div id='btnLoading' className="spinner-border spinner-border-sm " role="status" />
                            }
                        </button>

                        {/* {
                            logged
                            && 
                            <NavLink to='/sections/users' >
                                <button
                                    className="btn btn-lg w-100 btn-block mt-2 mb-1 clrBgPrimary textWhite"
                                >
                                    continuar como  <b>{userData.Apellido}</b>
                                </button>
                            </NavLink>
                        } */}

                    </div>
                </form>
            </div>
        </div>


    );
}