import {createSlice} from '@reduxjs/toolkit'

export const UserListSlices = createSlice({
    name: 'userList',
    initialState: [],
    reducers: {
        addUserList: (state, action) => {
            return action.payload
        }
    }
})

export const {addUserList} = UserListSlices.actions
export default UserListSlices.reducer