import {createSlice} from '@reduxjs/toolkit'

export const PendantOrderSlices = createSlice({
    name: 'pendantOrder',
    initialState: false,
    reducers: {
        addPendantOrder: (state, action) => {
            return action.payload
        }
    }
})

export const {addPendantOrder} = PendantOrderSlices.actions
export default PendantOrderSlices.reducer