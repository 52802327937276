import { useSelector } from "react-redux"

export const Header = ({ conectToSap }) => {
    const downloading = useSelector((state) => state.downloadElements)
    const sapConnected = useSelector((state) => state.sapConnected)
    const sapState = useSelector((state) => state.sapState)
    const userData = useSelector((state) => state.user)

    const handleReconnect = () => {
        if (!sapConnected && sapState === 'Desconectado') {     
                conectToSap()
        }
    }
    
    return (
        <header
            className='headBar clrBgHeader cursorDefault align-items-center fixed-top d-flex position-relative overflow-hidden'
        >
            <h3 className='fw-bold fontSize24 ms-5 me-auto '>
                PORTAL DE VENTAS
            </h3>

            {
                Object.values(downloading).includes(false)
                &&
                <span className="loaderIndi"> </span>
            }
            {
                userData.Sap
                &&
                <>
                    <span
                        className={`sapIndiA transition ${sapConnected ? 'borderBlue' : 'borderRed'}`}
                    ></span>
                    <span
                        onClick={handleReconnect}
                        disabled={sapState !== 'Desconectado'}
                        className={`sapIndiA sapIndiB cursorPointer transition ${sapConnected ? 'clrBgPeabBlue' : 'clrBgPrimary sapIndiASizeOverride sapIndiBPosOverride'}`}
                    ></span>
                    <span className="sapIndiC fw-bold pointerEventNone noSelect px-2">
                        {sapState} 
                    </span>
                </>
            }
        </header>
    )
}
