import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ProtectedRoute } from './components/tools/index'
import { Footer,Sections } from './components/ui/index'
import { Login } from './components/login/index'
import { AuthProvider, GeneralProvider } from './context/index';
import { PageNotFound404 } from "./PageNotFound404";

function App() {

    return (
        <div className="App">
            <AuthProvider>
                <GeneralProvider>
                    {/* Creamos rutas de navegacion */}
                    <Router>

                        <Routes>
                            {/* cualquier ruta te lleva a login */}
                            <Route path="/*" element={<Navigate to="/login" />} />

                            <Route path="/login" element={<Login />} />

                            <Route path="/sections/*" element=
                                {
                                    <ProtectedRoute>

                                        <Sections />

                                    </ProtectedRoute>
                                }
                            />

                            <Route path="*" element={<PageNotFound404/>}/>
                        </Routes>

                        <Footer />

                    </Router>
                </GeneralProvider>
            </AuthProvider>

        </div>
    );
}

export default App;
