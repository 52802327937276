import { createSlice } from '@reduxjs/toolkit'

const SearchFilterSlices = createSlice({
    name: 'searchFilter',
    initialState: '',

    reducers: {
        addFilter: (state, action) => {

            return action.payload
        }
    }
})

export const { addFilter } = SearchFilterSlices.actions
export default SearchFilterSlices.reducer