import { useGeneral } from "../../context";
import warnIco from '../../assets/images/advertenciaIco.png';
import editadoIco from '../../assets/images/escribirIco.png'

export const Order = ({ order }) => {

    const { sumProducts } = useGeneral()

    //funcion para formatear a pesos arg (formatter.format(num))
    const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
    });

    const checkOrdenCampo = (campo) => {
        let modif = false

        if (campo) {
            modif = true
        }
        return modif
    }

    return (

        <div
            className={`orderItem orderHeading position-relative parentGrid px-2 mb-2 textColorGrey align-items-center 
            clrGr${order.Estado.split(' ')[0]}`}
        >
            {/* ESTADO EN EL FONDO */}
            <span className={`position-absolute ordStateHeader fw-bold pe-2 ordSh${order.Estado.split(' ')[0]}`}>
                {
                    order.Estado === "Cargado en SAP"
                        ? 'SAP'
                        : order.Estado
                }
            </span>

            <div className="div1">
                <div className="fontSize15 d-flex flex-column">
                    <span
                        title={`Id pedido: \n${order.Num_pedido}`}
                        className="fontSizeSmallSM"
                    >
                        {order.Num_pedido}
                    </span>
                    <div className="d-flex flex-column ">
                        <span
                            title={`n° de orden: \n${order.NumPedidoDelCliente}`}
                            className="fw-light fontSize15 displayNoneSM"
                        >
                            # {order.NumPedidoDelCliente}
                        </span>
                    </div>
                </div>
            </div>

            <div className="div2 text-end ">
                {
                    order.DocNum
                    &&
                    <span className="fw-light fontSize11">
                        SAP &nbsp;
                        <span className="fontSize15 fontSizeSmallSM">
                            {order.DocNum}
                        </span>
                    </span>
                }
            </div>

            <div className="div3 fontSize18 text-end textLeftSM "
                title={`Cliente: \n${order.Cliente}`}>

                {order.Cliente}
            </div>


            <div className="div4 d-flex justify-content-end align-self-center">
                <img src={editadoIco}
                    className={`warnIco ${checkOrdenCampo(order.Modificador) ? '' : 'invisible'}`}
                    title='Orden Modificada'
                    alt="Ord_Modificada"
                />
                <img
                    src={warnIco}
                    className={`warnIco ps-2  ${(checkOrdenCampo(order.SapResponse) && order.Estado !== "Cargado en SAP") ? '' : 'd-none'}`}
                    title={order.SapResponse?.Response}
                    alt="Ord_Advertencia"
                />
            </div>

            <div className="div5 d-flex flex-column"
                title={order.PymntGroup.PymntGroup}
            >
                <span className="fw-light fontSize11 displayNoneSM">
                    Pago
                </span>
                <span className="fontSize13 fontSizeSmallSM">
                    {order.PymntGroup.PymntGroup}
                </span>
            </div>


            <div className="div6 text-end d-flex flex-column"
                title={`Productos: ${order.DocumentLines.length} \n\ ${formatter.format(order.SubTotal)}`}
            >
                <span className="fw-light fontSize11 displayNoneSM">
                    Unidades - Subtotal
                </span>
                <div className="d-flex justify-content-end">
                    <span className="fontSize13 displayNoneSM">
                        {sumProducts(order.DocumentLines)}&nbsp;- &nbsp;
                    </span>
                    <span className="fontSizeSmallSM">
                        {formatter.format(order.SubTotal)}
                    </span>
                </div>
            </div>


            <div className="div7 text-end d-flex flex-column align-self-end"
                title={`Vendedor: \n${order.VendedorApellido}`}
            >
                <span className="fontSize14">
                    {order.VendedorApellido}
                </span>
                <span className="fontSize13">
                    {order.Fecha}
                </span>
            </div>

        </div>

    )
}