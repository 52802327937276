import {createSlice} from '@reduxjs/toolkit'

export const ProductSlices = createSlice({
    name: 'productInfo',
    initialState: {},
    reducers: {
        addPoductInfo: (state, action) => {
            return action.payload
        }
    }
})

export const {addPoductInfo} = ProductSlices.actions
export default ProductSlices.reducer