

export const usuarios = [
    {
        Apellido: "Takas",
        AreaDeVentas: "31 - Gerencia Interior C",
        Cuit: "77899877",
        Gerencia: "admin",
        Id: "20",
        Mail: "taka@takas.com",
        Nombre: "taka",
        PID: "04",
        Permiso: "Sysadmin",
        Sap: false,
        Telefono: "654654",
        UID: "8P7SXiVs9ntJAgFsuyMA3bEDpvtS", //cambiar por el uid de autentcator y el nombre de documento tamb
        lastLogin: new Date()
    },
]

export const gerencias = [
    {
        Administracion: "04",
        AreaDeVentas: "1 - Gerencia",
        Cargo: "Gerente",
        Direccion: "01",
        DocID: "0mjj30JMxDLigcxDMmQk",
        Gerencia: "B02",
        Id: "1",
        Vendedor: "B03",
    }
]

export const condiciones = [
    {
        Condiciones: [
            {
                GroupNum: 167,
                PymntGroup: "60/120 DIAS",
                union: "right_only",
            }
        ],
        FechaActualizacion: new Date(),
        UltimaSincronizacion: new Date(),
    }
]

export const listaClientes = [
    {
        clientes: [
            {
                Acuerdo: NaN,
                Balance: 1,
                CUIT: "20925422860",
                Cliente: "CHOI DO MIN",
                Cliente_ID: "14855",
                Cod__Postal_Entrega: "1414",
                Cod__Postal_Factu: "1414",
                Contacto_Turno: NaN,
                DNoteBalFC: 0,
                DNoteBalSy: 0,
                DNotesBal: 0,
                DebtLine: 0,
                Descuento: 0,
                Direccion_Entrega: "CARRANZA ANGEL J. 1624",
                Direccion_Facturacion: "CARRANZA ANGEL J. 1624",
                Fecha_Ultima_Modificacion: NaN,
                Fecha_de_Alta: "2019-09-27",
                Grupo: 1,
                ID_Dpto_Ventas: 1,
                Inactivo: "N",
                Inactivo_desde: NaN,
                Inactivo_hasta: NaN,
                Iva: "CF",
                Limite_Credito: 0,
                Lista_Precios: 1,
                MUp: NaN,
                MUp_1: NaN,
                MUp_LineaB: NaN,
                MUp_LineaB_1: NaN,
                Nombre_Fantasia: NaN,
                Notas: NaN,
                OrderBalFC: 0,
                OrderBalSy: 0,
                OrdersBal: 0,
                Provincia_Entrega: "Ciudad Autónoma de Buenos Aires",
                Provincia_Facturacion: "Ciudad Autónoma de Buenos Aires",
                Publicidad: NaN,
                Publicidad_1: NaN,
                PymntGroup: "CONTADO 7 DIAS",
                ShipToDef: "ENTREGA",
                Telefono_1: NaN,
                Telefono_2: NaN,
                Turno: "No",
                Turno_1: "No",
                union: "both",
                validFor: "Y",
                validFrom: NaN,
                validTo: NaN,
            }
        ],
        FechaActualizacion: new Date(),
        UltimaSincronizacion: new Date(),
    }
]

export const listaPrecios = [
    {
        Precios: [
            {
                Currency: "ARS",
                Exclusivo_Gerencias: "N",
                ItemCode: "PE-ETA1001D",
                ItemName: "TERMO ELECTRICO GRIS METALIZADO - C/MANGO BOMBILLA",
                ItmsGrpCod: 162,
                ItmsGrpNam: "Producción Propia",
                PreVenta: "Y",
                Price: 46198.88,
                Stock: 179,
                union: "both",
            }
        ],
        FechaActualizacion: new Date(),
        UltimaSincronizacion: new Date(),
    }
]

export const listaDirecciones = [
    {
        Direcciones: {
            Calle: NaN,
            CardCode: '13874',
            Ciudad: NaN,
            Código_postal: NaN,
            Estado: NaN,
            Gerencia: 11,
            Número_de_la_calle: NaN,
            País: "AR",
            union: "right_only",
        }
    }

]

export const pedidos = [
    {zero: 'zero'}
]
export const mensajes = [
    {zero: 'zero'}
]