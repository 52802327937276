import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Faq, UserList } from "./index"
import { modalOpen } from "../../store/openModal/OpenModalSlices"
import { CondDePago } from "./CondDePago"
import { GroupGeren } from "./GroupGeren"
import { Getgerencia } from "../../data"


export const AdminPanel = ({ hadleDetail }) => {

    const permisos = ["Vendedor", "Gerencia", "Direccion", "Administracion", "Sysadmin"]
    const userData = useSelector((state) => state.user)
    const [subSect, setSubSect] = useState('faq')
    const [subSection, setSubSection] = useState('')
    const [modUser, setModUser] = useState({})
    const dispatch = useDispatch()
    
    const handleSubSection = (e) => {
        setSubSect(e.target.value)
    }
    
    const handleModalUser = (bool, sect, u = {}) => {
        setSubSection(sect)
        setModUser(u)
        dispatch(modalOpen({ a: bool }))
    }

    return (
        <>  
     <div className="d-flex p-2 " style={{gap:"0.5rem"}}>
                <button
                    className={`p-2 btn checkBtn text-uppercase ${subSect === 'faq' ? 'checkedBtn' : ''}`}
                    value='faq'
                    onClick={handleSubSection}
                >
                    Preguntas Frecuentes
                </button>

               
                    {/* {
                        ['Administracion', 'Sysadmin', 'Direccion'].includes(userData.Permiso)
                        &&
                        <>
                            <button
                                className={`p-2 btn checkBtn text-uppercase ${subSect === 'lista' ? 'checkedBtn' : ''}`}
                                value='lista'
                                onClick={handleSubSection}
                            >
                                Lista de Usuarios
                            </button>

                        </>
                    }
                    {
                        ['Administracion', 'Sysadmin', 'Direccion','Gerencia'].includes(userData.Permiso)
                        && 
                        <button 
                            className={`p-2 btn checkBtn text-uppercase ${subSect === 'gerencia' ? 'checkedBtn' : ''}`}
                            value='gerencia'
                            onClick={handleSubSection}
                            >
                               Asignacion de Clientes
                        </button>
                    }
                    {
                        ['Administracion', 'Sysadmin', 'Direccion'].includes(userData.Permiso)
                        &&
                        <button
                            className={`p-2 btn checkBtn text-uppercase ${subSect === 'pago' ? 'checkedBtn' : ''}`}
                            value='pago'
                            onClick={handleSubSection}>
                            Cond. de Pago
                        </button>
                    } */}

              
            </div>

            {
    
         <Faq hadleDetail={hadleDetail} />
        // : subSect === 'lista'
        //     ? <UserList subSection={subSection} permisos={permisos} hadleDetail={hadleDetail} handleModalUser={handleModalUser} modUser={modUser} />  
        //     : subSect === 'pago'? <CondDePago /> : <GroupGeren />
}

        </>
    )
}
