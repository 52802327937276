import { createSlice } from '@reduxjs/toolkit'

export const CondicionesDePagoSlices = createSlice({
    name: 'condicionesDePago',
    initialState: [],
    reducers: {
        addCondiciones: (state, action) => {

            return action.payload.sort((a,b) => {
                if (a.PymntGroup < b.PymntGroup) {
                    return -1;
                  }
                  if (a.PymntGroup > b.PymntGroup) {
                    return 1;
                  }
            })
        }
    }
})

export const { addCondiciones } = CondicionesDePagoSlices.actions
export default CondicionesDePagoSlices.reducer