import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CreateOrder,  OrderList } from './index'
import { CardHead, Loading } from '../ui/index'
import {  resetOrderData } from "../../store/orderData/OrderDataSlices"
import { modalOpen } from "../../store/openModal/OpenModalSlices"
import { addFilter } from "../../store/searchFilter/SearchFilterSlices"
import { useGeneral } from "../../context"
import filtroIco from "../../assets/images/filtrar.png"
import ordenIco from "../../assets/images/ordenar.png"

export const Pedidos = ({ closeSection }) => {

  const dispatch = useDispatch()
  const { convertToDate } = useGeneral()
  // filtro del buscador
  const filter = useSelector(state => state.searchFilter)
  const isOpenModal = useSelector(state => state.openModal)
  const fullOrd = useSelector((state) => state.orders)
  const orderInfo = useSelector((state) => state.orderData)
  const downloadElements = useSelector((state) => state.downloadElements)
  const userData = useSelector((state) => state.user)

  const [orderBy, setOrderBy] = useState('Fecha')
  const [orderList, setOrderList] = useState([])
  const [disableBoton, setDisableBoton] = useState(false)

  //cambiar el modo de filtrado para el filtro por estado
  const handleFilter = (stateFilter) => {
    dispatch(addFilter(stateFilter))
  }
  const handleOrder = ({ target: { name } }) => {
    setOrderBy(name)
  }

  const handleCreateOrder = () => {
    dispatch(modalOpen({ a: true }))
    dispatch(resetOrderData())
  }

  // 1/2 -devuelve la lista filtrada por el buscador
  const filteredOrders = () => {

    return (
      fullOrd.filter(ord => (
        ord.Cliente + " " + ord.Num_pedido + " " + ord.NumPedidoDelCliente + "" + ord.VendedorNombre + " " + ord.VendedorApellido + "" + ord.Estado + " " + ord.DocNum
      )
        .toUpperCase()
        .includes(filter.toUpperCase())
      )
    )
  }

  // 2/2 ordena la lista de ordenes
  const sortOrders = (allOrders) => {
    //filtra los pedidos Preliminar ajenos
    allOrders = allOrders.filter((ord) =>
      ord.Estado !== "Preliminar"
      || (ord.Estado === "Preliminar" && ord.SlpCode === userData.Id)
    )

    //ordena por fecha de más reciente a más antigua (sin horario :S te toca agregarlo a vos! aaacooo)
    if (orderBy === 'Fecha') {
      return allOrders.sort((a, b) => {
        const dateA = convertToDate(a.Fecha);
        const dateB = convertToDate(b.Fecha);

        return dateB - dateA;
      });
    }

    //por vendedor
    else if (orderBy === 'Vendedor') {
      return allOrders.sort((a, b) => (a.Num_pedido > b.Num_pedido ? -1 : 1));

      //por numero de sap
    } else if (orderBy === 'Sap-num') {
      return allOrders.sort((a, b) => {
        if (!a.DocNum && !b.DocNum) {
          return 0
        }
        if (!a.DocNum) {
          return 1
        }
        if (!b.DocNum) {
          return -1
        }
        return b.DocNum - a.DocNum
      });
    }
  }

  useEffect(() => {
    setOrderList(sortOrders(filteredOrders()))
  }, [fullOrd, filter, orderBy])

  useEffect(() => {
    if (userData.Permiso === "Administracion" && !userData.Sap){
      setDisableBoton(true)
    }
  },[])

  return (
    <div className="card maxHInherit pb35px">

      <CardHead section={'PEDIDOS'} searchBar={true} />

      {/* container de filtro y boton */}
      <div className="d-flex px-2 gap-2 minh46px p0SM h0px filterContainer px0SM">

        <button className="btn btn-sm  py0SM py-2 align-self-center dropdown-toggle ordFilter ordFilterSM"
          type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <img src={filtroIco} className="icon20px" />
          <span className="displayNoneSM"> Filtro</span>
        </button>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item" href="#" name="Todo" onClick={(e) => handleFilter('')}>Todo</a></li>
          <li><a className="dropdown-item" href="#" name="Pendiente" onClick={(e) => handleFilter(e.target.name)}>Pendiente</a></li>
          <li><a className="dropdown-item" href="#" name="Autorizado" onClick={(e) => handleFilter(e.target.name)}>Autorizado</a></li>
          <li><a className="dropdown-item" href="#" name="Cargado en SAP" onClick={(e) => handleFilter(e.target.name)}>Cargado en SAP</a></li>
          <li><a className="dropdown-item" href="#" name="Rechazado" onClick={(e) => handleFilter(e.target.name)}>Rechazado</a></li>
          <li><a className="dropdown-item" href="#" name="Preliminar" onClick={(e) => handleFilter(e.target.name)}>Preliminar</a></li>
        </ul>

        <button className="btn btn-sm  py0SM py-2 align-self-center dropdown-toggle ordFilter ordFilterSM"
          type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <img src={ordenIco} className="icon20px" />

          <span className="displayNoneSM"> Orden</span>
        </button>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item" href="#" name="Fecha" onClick={handleOrder} >Fecha</a></li>
          <li><a className="dropdown-item" href="#" name="Vendedor" onClick={handleOrder} >Vendedor</a></li>
          <li><a className="dropdown-item" href="#" name="Sap-num" onClick={handleOrder} >sap num</a></li>
        </ul>

        <button
          type="button"
          id="btnCreateOrder"
          className="btn btn-primary w-100 border border-2 rounded-2 border-light h64px btnCreateOrderSM"
          onClick={handleCreateOrder}
          disabled={disableBoton}
        >
          <span className="fs-1">CREAR PEDIDO</span>
        </button>

        {
          isOpenModal.a &&
          <CreateOrder section={'CREAR'} orderInfo={orderInfo.Estado === 'Preliminar' && orderInfo} closeSection={closeSection} />
        }
      </div>

      <div className="overflow-auto hInherit pb-2 pt02SM">
        {
          fullOrd.length > 0
            ?

            <OrderList orderBy={orderBy} orderList={orderList} pagination={true} />

            : !downloadElements.orders
              ? < Loading />
              : <h3 className="text-center mt-5">Aun no hay pedidos</h3>
        }
      </div>
    </div>
  )
}
