import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const ClientInfoSlices = createSlice({
    name: 'clientInfo',
    initialState,
    reducers: {
        addClientInfo: (state, action) => {
            // state.push(action.payload)
            //state[0] = action.payload
            return action.payload
        },

        resetClientInfo: (state, action) => {
            return initialState
        }
    }
})

export const { addClientInfo, resetClientInfo } = ClientInfoSlices.actions
export default ClientInfoSlices.reducer