import { useSelector } from 'react-redux';
import ImagenPerfil from '../../assets/images/ImagenPeabodyUser.png'

export const PerfilUser = () => {

  const userData = useSelector(state => state.user)

  //funcion provisoria para formatear el telefono y cuit, solo funciona con 10 numeros y 11
  const formatNumber = (numberString = 0) => {
    let cleaned = ('' + numberString).replace(/\D/g, '');
    let match

    if (numberString.length == 10) {
      match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
    } else if (numberString.length == 11) {
      match = cleaned.match(/^(\d{2})(\d{8})(\d{1})$/);
    }

    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    return cleaned;
  }

  //console.log(userData)
  return (
    <div className='row gx-0 pt-3 py0MD justify-content-center'>
      <div className='col-lg-4 col-md-4 col-sm-12 d-flex align-items-center justify-content-center '>
        <img className='userImg' src={ImagenPerfil} alt="imagen de perfil" />
      </div>
      <div className='col-lg-6 col-md-6'>
        <div className="col-sm-12 datosUser maxWFitCont longText">

          <ul className='list-group list-unstyled textColorGreyM gap-2 userList'>
            <li >
              <h1 className='text-uppercase fontSizeH1L fw-bold '>{userData.Apellido}</h1>
            </li>
            <li className='border-bottom pb-2'>
              <h3 className='fontSizeH3L '>{userData.Nombre}</h3>
            </li>
            <li className='fontSize18 d-flex justify-content-between'>
              <span className='me-3 fw-bold'>
                Permiso:
              </span>
              <span> {userData.Permiso}</span>
            </li>

            <li className='fontSize18 d-flex justify-content-between'>
              <span className='me-3 minWFitCont fw-bold'>Gerencia:</span>
              <span>{userData.Gerencia}</span>
            </li>

            <li className='fontSize18 d-flex justify-content-between'>
              <span className='me-3 minWFitCont fw-bold'>Area :</span>
              <span>{userData.AreaDeVentas}</span>
            </li>

            <li className='fontSize18 d-flex justify-content-between'>
              <span className='me-3 fw-bold'>
                Telefono:
              </span>
              <span> {formatNumber(userData.Telefono)}</span>
            </li>

            <li className='fontSize18 d-flex justify-content-between'>
              <span className='me-3 fw-bold'>
                E-mail:
              </span>
              <span>{userData.Mail}</span>
            </li>


            <li className='fontSize18 d-flex justify-content-between'>
              <span className='me-3 minWFitCont fw-bold'>Cuit:</span>
              <span>{formatNumber(userData.Cuit)}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

  )
};
