import userIco from '../../assets/images/user-light.png';
import clipboardIco from '../../assets/images/clipboard-check-light.png';
import bellIco from '../../assets/images/bell-light.png';
import signOut from '../../assets/images/sign-out-light.png';
import clientsIco from '../../assets/images/clients.png';
import cartIco from '../../assets/images/cartIco.png';
import ayudaIco from '../../assets/images/ayuda-ico.png';
import { useAuth } from "../../context/authcontext";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const SidebarUser = ({ cleanRdx }) => {

    // Llamamos a logout para realizar el cierre de sesion
    const { logout } = useAuth();
    const userData = useSelector((state) => state.user);

   //console.log(userData)

    // Funcion de navegacion
    const navigate = useNavigate();
    const isNewMessage = useSelector(state => state.newMessageIndi);
    const isPendantOrder = useSelector(state => state.pendantOrder);

    // Lista de categorías con su icono
    const categories = [
        { id: "users", category: "Cuenta", icon: userIco },
        { id: "orders", category: "Pedidos", icon: cartIco },
        { id: "clients", category: "Clientes", icon: clientsIco },
        { id: "pricelist", category: "Precios", icon: clipboardIco },
        { id: "notifications", category: "Notificaciones", icon: bellIco },
        { id: "Admin", category: "Admin", icon: userIco },
        { id: "help", category: "Ayuda", icon: ayudaIco },
        { id: "login", category: "Salir", icon: signOut }
    ];

    //  Filtrar categorías basadas en el permiso del usuario
    //  const filteredCategories = categories.filter(cat => !(cat.id === "Admin" && userData.Permiso !== 'Administrador'));
    const [filteredCategories, setFilteredCategories] = useState([]);

    //  Filtrar categorías basadas en el permiso del usuario
    useEffect(() => {
            if(userData.Permiso !== 'Administracion'){
                //console.log('vendedor')
            const filtered = categories.filter(cat => !(cat.id === "Admin" ));
            setFilteredCategories(filtered);
        } else {
                //console.log('admin')
            setFilteredCategories(categories);
        }

    }, [userData]);

    //  console.log(userData)
    //  console.log(filteredCategories)
    //  Funcion que ejecuta el cierre de sesion
    const handleLogout = async () => {
        try {
            cleanRdx();
            await logout();
            navigate("/");
        } catch (error) {
            console.log(error);
        }
    };

    // Funcion para el on click del menu, cambia la clase activa o cierra sesion
    const clickSectionHandler = (id) => {
        if (id === "login") {
            handleLogout();
        }
    };

    return (
        <div
            id='mySidebar'
            className="d-flex px0LG sidebarContainer shadow-sm flex-shrink-1 clrBgWhite
             sidebarSectionContainer sidebarRespContainer position-sticky pt-1 transition">

            <ul id="menu" className="nav flex-column sidebarUser ">
                {
                    filteredCategories.map((cat) => (
                        <li
                            key={cat.id}
                            className="nav-item menuCategory"
                        >
                            <NavLink
                                id={cat.id}
                                className={({ isActive }) => `position-relative catLink nav-link p-0 link-secondary ${isActive && cat.id ? 'activeCate' : ''}`}
                                aria-current="page"
                                onClick={(e) => clickSectionHandler(e.currentTarget.id)}
                                to={cat.id}
                            >
                                {
                                    ((cat.id === 'notifications') && isNewMessage)
                                    &&
                                    <div className='redDot' />
                                }
                                {
                                    ((cat.id === 'orders') && isPendantOrder)
                                    &&
                                    <div className='redDot' />
                                }

                                <div className='iconBox d-flex'>
                                    <img
                                        className="menuIco"
                                        src={cat.icon}
                                        alt={cat.id}
                                    />
                                </div>

                                <span className='align-middle px-1 spacing-1 textEllipsis text-center'>
                                    {cat.category}
                                </span>
                            </NavLink>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};