import { useState } from "react"

export const Switch = ({ text }) => {

    return (
        <div className="checkBtn">
            <label>
                <input type="checkbox" value="1" />
                <span>Action</span>
            </label>
        </div>)

}

export const TextSwitch = ({ onInputChange, section, text, checked, property }) => {

    return (
        <label
            className="checkText-container position-relative mb-1 px-2 d-flex cursorPointer"
            htmlFor={text}
            value={text}
            name="CategoriaMup"
        >

            <span className={`checkText ${checked ? 'textWhite fw-bold' : ''}`}>{text}</span>

            <input
                type="checkbox"
                id={text}
                name={property}
                value={text}
                checked={checked}
                className="d-none"
                onChange={(e) => onInputChange(e, section)}
            >
            </input>

            <div
                name="CategoriaMup"
                className={`slider ${checked ? 'checked' : ''}`}
            >
            </div>
        </label>
    )
}