import {createSlice} from '@reduxjs/toolkit'

export const ShowDivIdSlices = createSlice({
    name: 'showDivId',
    initialState: '',
    reducers: {
        addShowDivId: (state, action) => {
            return action.payload
        },

    }
})

export const {addShowDivId} = ShowDivIdSlices.actions
export default ShowDivIdSlices.reducer