import {createSlice} from '@reduxjs/toolkit'

export const GerenciasSlices = createSlice({
    name: 'gerencias',
    initialState: [],
    reducers: {
        addGerencias: (state, action) => {
            // state.push(action.payload)
            //state[0] = action.payload
            return action.payload
        }
    }
})
export const {addGerencias} = GerenciasSlices.actions
export default GerenciasSlices.reducer