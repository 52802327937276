import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Getgerencia, overLoadIdVendedores, uploadIdVendedores } from '../../data';
import editIco from '../../assets/images/editIco.png';
import check from '../../assets/images/tick.png';
import del from '../../assets/images/trashIco.png';

export const GroupGeren = () => {
    const downloading = useSelector((state) => state.downloadElements);
    const userList = useSelector((state) => state.userList);
    const clients = useSelector(state => state.clients);
    const vendedores = useSelector((state) => state.vendedores);
    const [geren, setGeren] = useState([]);
    const [clientsFiltrados, setClientsFiltrados] = useState([]);
    const [userSeleccionado, setUserSeleccionado] = useState([]);
    const [gerenteSeleccionados, setGerenteSeleccionados] = useState([]);
    const [forUpload, setForUpload] = useState("");
    const [nombreCompleto, setNombreCompleto] = useState([]);
    const [aCheckear, setACheckear] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editModeMap, setEditModeMap] = useState({});
    const [finalUser, setFinalUser] = useState({
        IdVendedor: '',
        Clientes: [],
        IdName: '',
        Permiso: ''
    });
    const [selectedID, setSelectedID] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const gerencia = await Getgerencia();
            setGeren(gerencia);
        };
        fetchData();
    }, []);

    useEffect(() => {
        createIdZona(finalUser);
    }, [finalUser]);

    const handleIDZChange = (event) => {
        setSelectedID(event.target.value);
    };

    useEffect(() => {
        const selectedIdNumber = parseInt(selectedID, 10);
        const filteredUsers = clients.filter(cliente => cliente.ID_Dpto_Ventas === selectedIdNumber);
        setClientsFiltrados(filteredUsers);

        const selectedGerentes = geren.filter(gerente => parseInt(gerente.Id, 10) === selectedIdNumber);
        setGerenteSeleccionados(selectedGerentes);

        setEditMode(false);
        setSearchTerm('')
    }, [selectedID]);

    useEffect(() => {
        const vendedor = gerenteSeleccionados.map(gerente => gerente.Vendedor);
        const filteredUserList2 = userList.filter(user => user.PID === vendedor[0]);
        const combinedUserList = [...filteredUserList2];
        fetchClients(combinedUserList);
        setSearchTerm('')
    }, [gerenteSeleccionados]);

    const fetchClients = (combinedUserList) => {
        const filteredAndMappedUserList = combinedUserList.filter(user => user.Apellido !== 'Chiachio');
        const nombresCompletos = combinedUserList.map(user => `${user.Nombre}_${user.Apellido}`);
        setNombreCompleto(nombresCompletos);
        setUserSeleccionado(filteredAndMappedUserList);
        const initialEditModeMap = {};
        filteredAndMappedUserList.forEach(user => {
            initialEditModeMap[`${user.Nombre}_${user.Apellido}`] = false;
        });
        setEditModeMap(initialEditModeMap);
    };

    useEffect(() => {

        const nombres = userSeleccionado.map(user => `${user.Nombre}_${user.Apellido}`);
        const usuariosEncontrados = {};
        nombres.forEach(nombre => {
            const encontrarNombre = vendedores.find(vendedor => vendedor.IdName === nombre);

            if (encontrarNombre) {
                usuariosEncontrados[nombre] = encontrarNombre.Clientes || [];
            } else {
                usuariosEncontrados[nombre] = [];
            }
        });
        setACheckear(usuariosEncontrados);
    }, [userSeleccionado]);

// habilita la edicion cuando EditMode esta en true, y tambien busca  los clientes previos que estna en firebase que ya tiene asignados
    const godMode = (Nombre, Apellido) => {
        const nm = `${Nombre}_${Apellido}`;
        setEditModeMap({ [nm]: true });
        setEditMode(true);
        if (aCheckear.hasOwnProperty(nm)) {
            setFinalUser(prevState => ({
                ...prevState,
                IdName: nm,
                Clientes: [...aCheckear[nm]],
            }));
        }
    };

    // si el input  ischecked se agrega si no se Elimina (dice repuesto por que es una copia de otra funcion)
    const functionPruv = (cuit, pid, isChecked, Nombre, Apellido, Permiso) => {
        if (isChecked) {
            setFinalUser(prevState => ({
                ...prevState,
                IdVendedor: pid,
                IdName: `${Nombre}_${Apellido}`,
                Clientes: [...prevState.Clientes, cuit],
                Permiso: Permiso
            }));
        } else {
            EliminarRepuesto(cuit);
        }
    };

    const EliminarRepuesto = (r) => {
        setFinalUser(prevState => {
            const newClientes = prevState.Clientes.filter(cliente => cliente !== r);
            return {
                ...prevState,
                Clientes: newClientes
            };
        });
    };

    // creacion del idzona, para el objeto en firebase
    const createIdZona = (ver) => {
        const gerenEncontrada = geren.filter(geren => geren[ver.Permiso] == ver.IdVendedor);
        const ids = gerenEncontrada.map(geren => geren.Id);
        const IdZona = ids.join(', ');
        const verConIdZonas = { ...ver, IdZona };

        setForUpload(verConIdZonas);
    };

    const isLoading = Object.values(downloading).includes(false);

    //Le mandamos el documento a la funcion de firebase
    const handleForSubmit = () => {
        uploadIdVendedores(forUpload);
        setTimeout(() => {
            window.alert("La subida se ha completado correctamente.");
            window.location.reload();
        }, 3000);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    //filtro de clientes 
    const filteredClients = clientsFiltrados.filter(cliente =>
        cliente.Cliente.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            {isLoading ? (
                <div className='d-flex justify-content-center' style={{ margin: '15rem' }}>
                    <div style={{ background: "#4b4949", borderRadius: "15px", width: "15rem", height: "18rem" }}>
                        <div className='d-flex justify-content-center'>
                            <span className="loaderIndi2"></span>
                        </div>
                        <div className="load-wrapp" style={{ width: '100%' }}>
                            <div className="load-3">
                                <div className="line">c</div>
                                <div className="line">a</div>
                                <div className="line">r</div>
                                <div className="line">g</div>
                                <div className="line">a</div>
                                <div className="line">n</div>
                                <div className="line">d</div>
                                <div className="line">o</div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='m-5'>
                    <div className='d-flex  align-items-center'>
                        <p style={{ paddingRight: '10px' }}>Seleccionar zona: </p>
                            <select style={{ width: '15rem', height: '30px' }} onChange={handleIDZChange}>
                                {geren
                                    .sort((a, b) => {
                                        const numA = parseInt(a.AreaDeVentas.split(' ')[0]);
                                        const numB = parseInt(b.AreaDeVentas.split(' ')[0]);
                                        return numA - numB;
                                    })
                                    .map((geren, index) => (
                                        <option key={index} value={geren.Id}>
                                            {geren.AreaDeVentas}
                                        </option>
                                    ))}
                            </select>
                                
                            <div className='ms-auto bd-highlight'>
                                {editMode && <button className='btn btn-success' onClick={handleForSubmit}>Agregar</button>}
                            </div>

                            <div className='d-flex gap-2' >
                                <p className=''>Buscar Por Nombre :</p>
                                <input style={{ width: '15rem', height: '30px' }} type="text" value={searchTerm} onChange={handleSearchChange} />
                            </div>
                    </div>


                    <div style={{ margin: '10px' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Nombre clientes</th>
                                    {userSeleccionado && Object.keys(userSeleccionado).map((key, indx) => (
                                        <th key={indx} style={{ marginLeft: '25px', marginRight: '25px' }}>
                                            <p>{userSeleccionado[key].Nombre} {userSeleccionado[key].Apellido}</p>
                                            <div className='d-flex justify-content-center' style={{ textAlign: 'center' }}>
                                                <button
                                                    className='btn btn-secondary d-flex justify-content-center'
                                                    style={{ width: '35px', height: '35px' }}
                                                    onClick={() => godMode(
                                                        userSeleccionado[key].Nombre,
                                                        userSeleccionado[key].Apellido
                                                    )}>
                                                    <img src={editIco} alt="Edit Icon" />
                                                </button>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredClients && filteredClients.map((cliente, ix) => (
                                    <tr key={ix}>
                                        <td>{cliente.Cliente}</td>
                                        {userSeleccionado && Object.keys(userSeleccionado).map((key, inx) => {
                                            const getBackgroundColor = () => {
                                                if (aCheckear[userSeleccionado[key].Nombre + '_' + userSeleccionado[key].Apellido] &&
                                                    aCheckear[userSeleccionado[key].Nombre + '_' + userSeleccionado[key].Apellido].includes(cliente.CUIT))
                                                    return 'lightgray';
                                            }
                                            return (
                                                <td key={inx} style={{ textAlign: 'center', verticalAlign: 'middle', width: '10px' }}>
                                                    <input
                                                        type="checkbox"
                                                        {...(!editMode && {
                                                            style: {
                                                                backgroundColor: getBackgroundColor(),
                                                                width: '20px',
                                                                height: '20px',
                                                                border: '1px solid #ccc',
                                                                appearance: 'none',
                                                                WebkitAppearance: 'none',
                                                                MozAppearance: 'none'
                                                            }
                                                        })}
                                                        id={`${cliente.Cliente}_${key}`}
                                                        name={`${cliente.Cliente}_${key}`}
                                                        onClick={(event) => {
                                                            functionPruv(
                                                                cliente.CUIT, userSeleccionado[key].PID,
                                                                event.target.checked, userSeleccionado[key].Nombre,
                                                                userSeleccionado[key].Apellido,
                                                                userSeleccionado[key].Permiso,
                                                                event.target.defaultChecked
                                                            )
                                                        }}
                                                        disabled={!editModeMap[`${userSeleccionado[key].Nombre}_${userSeleccionado[key].Apellido}`]}
                                                        {...editMode && {
                                                            defaultChecked: aCheckear[userSeleccionado[key].Nombre + '_' + userSeleccionado[key].Apellido] &&
                                                                aCheckear[userSeleccionado[key].Nombre + '_' + userSeleccionado[key].Apellido].includes(cliente.CUIT)
                                                        }}
                                                    />
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
};