import { toast } from 'react-toastify';


export const ToastError = (message) => {
    return ( 
        toast.error(message, {
            position: "top-right",
            autoClose: 10000,
            newestOnTop: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
    )
}

export const ToastSucces = (message) => {
    return (
        toast.success(message, {
            position: "top-right",
            newestOnTop: false,
            autoClose: 10000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
    )
}

export const ToastWarn = (message) => {
    return (
        toast.warn(message, {
            position: "top-right",
            newestOnTop: false,
            autoClose: 10000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
    )
}


export const Alert = () => {
    const errorMsg = "ERROR: Credenciales no validas."

    return (
        <div className='text-end'>
            <p className='errMsg textRed'>{errorMsg}</p>
        </div>
    );
};