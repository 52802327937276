import editar from '../../assets/manual/Captura.PNG'
import pedidos from '../../assets/manual/Captura 2a.png'
import cpedidos from '../../assets/manual/cpedi.PNG'
import rpedidos from '../../assets/manual/Captura 3.PNG'
import clientes from '../../assets/manual/clientes.png'
import nclientes from '../../assets/manual/Captura 4.png'
import precio from '../../assets/manual/precio.png'
import noti from '../../assets/manual/noti.png'
import exportar from '../../assets/manual/exportar.png'
import { useState } from 'react'
import { useSelector } from 'react-redux'


export const Faq = ({ hadleDetail }) => {
    const listaAyuda = [
        {
            id: 'E1',
            Titulo: '¿Como edito mis datos?',
            Descripcion: 'Dentro de la sección "Mi cuenta" arriba a la derecha se encontrará el siguiente botón: ',
            Imagen: editar
        },
        {
            id: 'E2',
            Titulo: '¿Cómo veo los pedidos?',
            Descripcion: ' En la sección "Pedidos" tendrá el listado de todos los pedidos. Se puede filtrar por pendiente, autorizado, cargado en sap, rechazado y preliminar',
            Imagen: pedidos
        },
        {
            id: 'E3',
            Titulo: '¿Cómo creo un pedido?',
            Descripcion: 'Para crear un pedido deberá ir a la sección de "Pedidos" y una vez ahi usar el botón de crear pedido.',
            Imagen: cpedidos
        },
        {
            id: 'E4',
            Titulo: '¿Cómo rechazo pedidos o los envío a SAP(Usuario Gerente)?',
            Descripcion: 'Al entrar en un pedido podrá rechazar el pedido y escribir la razón del rechazo o mandarlo a sap.ara crear un pedido deberá ir a la sección de "Pedidos" y una vez ahi usar el botón de crear pedido.',
            Imagen: rpedidos
        },
        {
            id: 'E5',
            Titulo: '¿Dónde veo los clientes y como los busco?',
            Descripcion: 'En la sección "Mis clientes" tendrá un listado de los clientes registrados, donde podrá ver el listado completo con todos los datos, Para ordenar los datos en orden ascendente o descendente clickear el encabezado de la columna y, tambien puede buscar los clientes en específico utilizando el buscador ya sea por su nombre o CUIT.',
            Imagen: clientes
        },
        {
            id: 'E6',
            Titulo: '¿Cómo puedo ver los datos del cliente?',
            Descripcion: 'Si necesita ver los datos del cliente tendrá que buscarlo, una vez encontrado, debe hacer click sobre el nombre del cliente y saldrán los datos del mismo',
            Imagen: nclientes
        },
        {
            id: 'E7',
            Titulo: '¿Dónde veo la lista de precios?',
            Descripcion: 'En la sección "Precios" encontrará el listado de los productos, con su codigo y precios.',
            Imagen: precio,
        },
        {
            id: 'E8',
            Titulo: '¿Cómo exporto la lista de precios?',
            Descripcion: 'Para esto debe usar el botón "Exportar" de la sección de "Precios" , se le descargará un archivo .xls para que se abra con un programa de hoja de cálculo como excel',
            Imagen: exportar,
        },
        {
            id: 'E9',
            Titulo: '¿Por qué tengo notificaciones?¿Cómo respondo a una notificación?',
            Descripcion: 'Las notificaciones de la sección con el mismo nombre son de los pedidos que se rechazaron, estos vendrán con un mensaje por el motivo del rechazo y podrá responder haciendo clic en el mensaje.',
            Imagen: noti,
        },
    ]

    const [imagen, setImagen] = useState(null)

    const openDetail = useSelector(state => state.openDetail)


    const handleZoom = (e) => {
        const buscar = listaAyuda.find((i) => i.id === e.target.id).Imagen
        setImagen(buscar)
        document.getElementById("modalHelp").classList.add("abierto")
    }

    const handleCloseModalHelp = () => {
        setImagen(null)
        document.getElementById("modalHelp").classList.remove("abierto")
    }



    return (
        <div className=''>
            <h4 className='separar my-4'>
                <b className='text-uppercase'>Preguntas Frecuentes</b>
            </h4>
            <ul className='listaHelp px-2'>

                {
                    listaAyuda.map((i) => {
                        return (
                            <li key={i.id} className='mb-3'>
                                <details
                                    className='detailsHelp '
                                    open={openDetail === i.id}
                                >
                                    <summary
                                        id={i.id}
                                        onClick={(e) => hadleDetail(i.id, e)}
                                        className='summaryHelp'
                                    >
                                        <b >
                                            {i.Titulo}
                                        </b>
                                    </summary>
                                    <div className=' clrBgLightGray'>
                                        <p className='respuesta pt-2'>{i.Descripcion}</p>
                                        <img id={i.id} onClick={handleZoom} src={i.Imagen} className='ajustar' alt="imagen_de_referencia" />
                                    </div>
                                </details>
                            </li>
                        )
                    }
                    )
                }

                <dialog id="modalHelp" open={imagen ? true : false}>
                    <div className='modalHelpSub'>
                        <button id='cerrar'
                            onClick={handleCloseModalHelp}
                            className="btn-close centerEnd close-img btnCardHead ms-4 p-0 me-2">
                        </button>
                        <img src={imagen} alt="X" className='imgHelp' />
                    </div>
                </dialog>
            </ul>
        </div>
    )
}
