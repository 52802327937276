import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CardHead, Loading } from '../ui/index'
import { TableTks } from '../tools/TableTks'

export const Clientes = () => {

  const [filter, setFilter] = useState('');

  const downloading = useSelector((state) => state.downloadElements)

  return (
    <div className="card maxHInherit">

      <CardHead section={'CLIENTES'} filter={filter} setFilter={setFilter} searchBar={true} />

      {
        !Object.values(downloading).includes(false)
          ? <TableTks section={'CLIENTES'} />
          : <Loading />
      }

    </div>
  )
}
