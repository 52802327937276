import { createSlice } from "@reduxjs/toolkit"

const initialState = {pedidos: false, mensajes: false}

export const TestIndicatorSlices = createSlice({
    name: 'testIndicator',
    initialState,
    reducers: {
        addTestIndicator: (state, action) => {
            return {...state, ...action.payload }
        },
        
        clearTestIndicator: (state, action) => {
            return initialState
        },
    }
})

export const {addTestIndicator, clearTestIndicator} = TestIndicatorSlices.actions
export default TestIndicatorSlices.reducer