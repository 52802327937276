import { useEffect, useState } from 'react';
import { doc, setDoc, query, onSnapshot, getDoc, collection, getDocs, where, getCountFromServer, updateDoc, addDoc, arrayUnion } from "firebase/firestore";
import { useAuth } from "../context/authcontext";
import { db } from '../data/index';
import { SlackErrorHandle, ToastError, ToastSucces } from '../components/tools/index'

//deberia ejecutarse 1ra revisar
export const Getuserdata = () => {

    //Traemos los datos del usuario logueado
    const { user } = useAuth();

    //Extraemos todos los campos del documento consultado
    const [userdata, setUserdata] = useState({});
    
    const Get = async () => {
        try {
            const docRef = query(doc(db, 'Usuarios', user.uid))

            //subscribe
            const unsubscribe = onSnapshot(docRef, (query) => {
                const userd = query.data()
                setUserdata({ ...userd })
            })

            //cleanup
            return () => unsubscribe()
        } catch (error) {
            console.log(error)
            SlackErrorHandle(`${new Date()}: \n-> Fallo en la descarga de usuario \n-> *${error}* \n-> User: ${user.Mail}, error en Get`)
        }
    }

    useEffect(() => {
        try {
            Get();
        } catch (error) {
            console.log(error)
        }

    }, []);

    return (
        userdata
    )
};

//funcion para ver si un objeto esta vacio
export const isObjEmpty = (obj) => {
    return (obj && Object.keys(obj).length === 0)
}

// funcion generica para traer documentos de fire
export const getDocumentById = async (collectionName, documentId) => {

    try {
        const docRef = doc(db, collectionName, documentId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return { ...docSnap.data() };
        }

    } catch (error) {
        console.log("No existe un documento con ese ID: ", documentId, error);
        //SlackErrorHandle(`${new Date()}: -> No existe un documento con ese ID:  ${documentId}, error en getDocumentById `)
    }

};

//funcion que recibe una lista de ids y trae todos los doc en una lista
export const getDocsByIds = async (collectionName, idList) => {
    let docs = []

    for (const id of idList) {
        const docSnap = await getDocumentById(collectionName, id)

        if (docSnap) {
            docs.push(docSnap)
        } else {
            console.log('error en getdocs')
            //SlackErrorHandle(`${new Date()}: -> error en getDocsByIds  `)
        }
    }

    return docs
}


// recibe la ruta de la coleccion, el id del documento, y el key del objeto 
// modifica el estado de los mensajes dentro del objeto especificado a 'Leido'
export const updateMessageState = async (fbCollection, documentId, orderId, userNomApe) => {
    try {
        const docRef = doc(db, fbCollection, documentId)
        const docSnap = await getDoc(docRef)

        let data = docSnap.data()
        let updateObj = {...data[orderId], NuevoMensaje:false}
        // Verifica si la propiedad Mensaje existe

        if (updateObj.Mensaje[0]) {
            const mensajes = updateObj.Mensaje

            // Iterar sobre los mensajes
            for (let i = 0; i < Object.keys(updateObj.Mensaje).length; i++) {
                const mensaje = mensajes[i]

                if (
                    !mensaje.Leido
                    &&
                    mensaje.User !== userNomApe) {

                    updateObj.Mensaje[i] = {
                        ...mensaje,
                        Leido: true,
                    };
                }
            }
        }

        await updateDoc(docRef, {
            [orderId]: updateObj
        })

    } catch (error) {
        console.log("Error al actualizar los estados de los mensajes:", error);
        SlackErrorHandle(`${new Date()}: \n-> *Fallo al actualizar notificaciones* \n-> ${error} \n-> User: ${userNomApe}`)
    }
}

export const updateRevision = async (fbCollection, documentId, userName, state) => {

    const docRef = doc(db, fbCollection, documentId)

    await updateDoc(docRef, {
        Revision: userName,
        FechaDeRevision: new Date().toLocaleDateString(),
        Estado: state,
    });
}

export const getIdVendedores = async () => {
    try {
        const clientesSnapshot = await getDocs(query(collection(db, 'IdVendedores')));

        const clientes = [];

        clientesSnapshot.forEach((doc) => {
            clientes.push({ id: doc.id, ...doc.data() });
        });

        return clientes;
    } catch (error) {
        console.error('Error al obtener los documentos de la colección "IdVendedores":', error);
        SlackErrorHandle(`${new Date()}: \n-> *Error al obtener los documentos de la colección "IdVendedores* -> ${error} `)
        throw error;
    }
};





//recibe un docref y datos sube info a firebase
export const uploadIdVendedores = async (objetoClientes) => {
    console.log(objetoClientes);
    try {
        const q = query(collection(db, 'IdVendedores'), where('IdName', '==', objetoClientes.IdName));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size > 0) {
            querySnapshot.forEach(async (doc) => {
                const existingData = doc.data();
                const newData = {
                    ...existingData,
                    Clientes: [ ...objetoClientes.Clientes]
                };
             //   console.log(newData)
           await setDoc(doc.ref, newData);
                SlackErrorHandle(`${new Date()}: \n-> *Documento actualizado con clientes adicionales: * -> ${doc.id}  error en uploadIdVendedores`)
        
            });
        } else {
            const newDocRef = doc(collection(db, 'IdVendedores'));
            //console.log(objetoClientes)
            await setDoc(newDocRef, objetoClientes);
            SlackErrorHandle(`${new Date()}: \n-> *Nuevo documento creado con ID aleatorio: * -> ${newDocRef.id}  error en uploadIdVendedores`)
        
        }
    } catch (error) {
        SlackErrorHandle(`${new Date()}: \n-> *Error al agregar o actualizar el * -> ${error}  error en uploadIdVendedores`)
        throw error;
    }
};

export const overLoadIdVendedores = async (objetoClientes) => {
    console.log(objetoClientes);
    try {
        const q = query(collection(db, 'IdVendedores'), where('IdName', '==', objetoClientes.IdName));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size > 0) {
            querySnapshot.forEach(async (doc) => {
                const existingData = doc.data();
                const newData = {
                    ...existingData,
                    Clientes: [ ...objetoClientes.Clientes]
                };
            await setDoc(doc.ref, newData);
                console.log('Documento actualizado con clientes adicionales:',doc.id);
            });
        }
       
    } catch (error) {
       // console.error('Error al agregar o actualizar el cliente:', error);
        SlackErrorHandle(`${new Date()}: \n-> *Error al agregar o actualizar el * -> ${error}  error en overLoadIdVendedores`)
        throw error;
    }
};


export const Adddata = async (docRef, updateData) => {
    try {
        await setDoc(docRef, updateData, { merge: true });

    } catch (error) {
        // console.log('fallo en la actualizacion de datos (firebase)', error)
        SlackErrorHandle(`${new Date()}: \n-> *fallo en la actualizacion de datos (firebase) * -> ${error}  error en Adddata`)
    }
}

export const SLCred = () => {

    const [cred, setCred] = useState()
    const { user } = useAuth();

    useEffect(() => {
        if (user) {
            const Get = async () => {
                const docRef = doc(db, 'ServiceLayerCred', 'Credenciales')
                const docSnap = () => getDoc(docRef)
                const querySnapshot = await docSnap();
                const userd = querySnapshot.data();
                setCred(userd)
            }
            Get();
        }
    }, [user]);
    return cred
}

//recibe los datos del usuario. da la lista de ids propio +  sus vendedores
export const Getgerenciaid = async (userData) => {
    
    try {
        let Gerenciaid = []

        const docRef = query(collection(db, 'Gerencias'),
            where(userData.Permiso, "==", userData.PID))

        const docSnap = async () => getDocs(docRef)
        const querySnapshot = await docSnap();
        querySnapshot.forEach((doc) => {
            const userd = doc.data();
            Gerenciaid.push(userd.Id)
        })
       //console.log(Gerenciaid)
        return Gerenciaid
    } catch (error) {
        console.log('getgerencia', error)
        SlackErrorHandle(`${new Date()}: \n-> *fallo en la toma de gerenciasId (firebase) * -> ${error}  error en Getgerenciaid`)
    }
}

export const Getgerencia = async () => {
    try {
        const gerencias = [];
        
        const docRef = collection(db, 'Gerencias');
        const q = query(docRef, where("Activo", "==", true));
        const querySnapshot = await getDocs(q);
        
        querySnapshot.forEach((doc) => {
            const gerenciaData = doc.data();
            gerencias.push(gerenciaData);
        });
        
       //console.log('Gerencias obtenidas:', gerencias);
        return gerencias;
    } catch (error) {
        console.error('Error al obtener las gerencias:', error);
        SlackErrorHandle(`${new Date()}: \n-> *fallo en la toma de gerencias (firebase) * -> ${error}  error en Getgerencia`)
        throw error;
    }
};

    
export const AddOrder = async (OrderFields, collectionPedidos) => {
    //SlpCode es el id
    const query_ = query(collection(db, collectionPedidos), where("SlpCode", "==", OrderFields.SlpCode));
    const snapshot = await getCountFromServer(query_);
    const cantPedidos = snapshot.data().count;

    const zfill = (number, width) => {
        var numberOutput = Math.abs(number); /* Valor absoluto del número */
        var length = number.toString().length; /* Largo del número */
        var zero = "0"; /* String de cero */

        if (width <= length) {
            if (number < 0) {
                return ("-" + numberOutput.toString());
            } else {
                return numberOutput.toString();
            }
        } else {
            if (number < 0) {
                return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
            } else {
                return ((zero.repeat(width - length)) + numberOutput.toString());
            }
        }
    }

    try {
        let docRef = doc(collection(db, collectionPedidos))
        let numPedido = OrderFields.SlpCode + "-" + zfill(cantPedidos + 1, 8)

        if (OrderFields.ID) {

            docRef = query(doc(db, collectionPedidos, OrderFields.ID))

            await setDoc(docRef, { ...OrderFields });
        } else {

            await setDoc(docRef, {
                 ...OrderFields, 
                 ID: docRef.id, 
                 Num_pedido: numPedido,
                 Comments: 'ID: ' + numPedido +', '+  OrderFields.Comments
                });
        }

        ToastSucces('Orden cargada')
        SlackErrorHandle(`${new Date()}: -> Orden cargada a sap -> ${OrderFields.Num_pedido} `)
    } catch (error) {
        console.log(error)
        ToastError(`Fallo en la carga: ${error}`)
        SlackErrorHandle(`${new Date()}: \n-> *Fallo en la subida del pedido a FB* \n-> ${error} \n-> User: ${OrderFields.VendedorApellido}`)
    }
}


export const CreateUser = async (newuserdata) => {
        //aca se sube
        //console.log(newuserdata)
    try {
        let docRef = doc(db, 'Usuarios', newuserdata.UID)

        await setDoc(docRef, newuserdata );
        ToastSucces('Nuevo usuario creado')

    } catch (error) {
        console.log(error)
        ToastError(`Fallo en la creacion de usuario: ${error}`)
        SlackErrorHandle(`${new Date()}: \n-> *Fallo en la creacion de usuario * -> ${error}  CreateUser`)
    }
}

export const FilterArraySellers = async (gerencia, userData) => {
    const nm = `${userData.Nombre}_${userData.Apellido}`;

    try {
        const docReft = await getDocs(query(collection(db, 'IdVendedores')));
        let idvendeData = [];

        // Collect all client IDs for the matching vendor name
        docReft.forEach((doc) => {
            console.log(doc.data().IdName)
            if (doc.data().IdName === nm) {
                idvendeData = idvendeData.concat(doc.data().Clientes);
            }
        });

        let clientData = [];

        // Fetch and filter clients
        const promises = gerencia.map(async (docName) => {
            const docRef = doc(db, "Lista_Clientes", docName);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const clientes = docSnap.data().Clientes;
                const clienteEncontrado = clientes.filter(cliente => idvendeData.includes(cliente.CUIT));

                if (clienteEncontrado.length > 0) {
                    clientData.push({
                        Clientes: clienteEncontrado,
                        FechaActualizacion: docSnap.data().FechaActualizacion,
                        UltimaSincronizacion: docSnap.data().UltimaSincronizacion
                    });
                }
            } else {
                console.log(`El documento "${docName}" no existe.`);
            }
        });

        await Promise.all(promises);
        return clientData;
    } catch (error) {
        console.error("Error al filtrar los documentos:", error);
        return []; // Return an empty array in case of error
    }
};