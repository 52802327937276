import { createSlice } from '@reduxjs/toolkit';

export const vendedoresSlice = createSlice({
    name: 'vendedores',
    initialState: [],
    reducers: {
        setVendedores: (state, action) => {
            return action.payload;
        }
    }
});

export const { setVendedores } = vendedoresSlice.actions;

export default vendedoresSlice.reducer;