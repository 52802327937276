import { useDispatch, useSelector } from 'react-redux'
import { CardHead } from '../ui'
import { Faq, AdminPanel } from './index'
import { useEffect } from 'react'
import { detailOpen } from '../../store/openModal/OpenDetailSlices'


export const Ayuda = () => {

  const userData = useSelector(state => state.user)
  const dispatch = useDispatch()

  const hadleDetail = (id, e) => {
    e.preventDefault()

    dispatch(detailOpen(id))
  }

  useEffect(() => {
      return () => {
        dispatch(detailOpen(''))
      }
    }, [])


  return (

    <div className="card">
      <CardHead section={userData.Permiso !== 'Master' ? 'AYUDA' : 'PANEL DE CONTROL'} />
          <AdminPanel hadleDetail={hadleDetail} />
    </div>
  )
}
