import {createSlice} from '@reduxjs/toolkit'

export const DirectionsSlices = createSlice({
    name: 'clientsDirections',
    initialState: [],
    reducers: {
        addDirections: (state, action) => {
            // state.push(action.payload)
            //state[0] = action.payload
            return action.payload
        }
    }
})

export const {addDirections} = DirectionsSlices.actions
export default DirectionsSlices.reducer