import {createSlice} from '@reduxjs/toolkit'

export const RefMessageSlices = createSlice({
    name: 'refMessage',
    initialState: {},
    reducers: {
        addRefMessages: (state, action) => {
            return action.payload
        },

    }
})

export const {addRefMessages} = RefMessageSlices.actions
export default RefMessageSlices.reducer