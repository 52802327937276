import {createSlice} from '@reduxjs/toolkit'

export const MessagesUIDsSlices = createSlice({
    name: 'messagesUIDs',
    initialState: [],
    reducers: {
        addMessagesUIDs: (state, action) => {
            return action.payload
        },

    }
})

export const {addMessagesUIDs} = MessagesUIDsSlices.actions
export default MessagesUIDsSlices.reducer