import {createSlice} from '@reduxjs/toolkit'

export const ClientSyncSlices = createSlice({
    name: 'clientSync',
    initialState:0,
    reducers: {
        addClientSync: (state, action) => {
            return action.payload
        },
    }
})

export const {addClientSync} = ClientSyncSlices.actions
export default ClientSyncSlices.reducer