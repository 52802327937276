import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const OrdersSlices = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        addOrders: (state, action) => {
            // create a map to keep track of the items
            const ordersMap = new Map()

            // add the new orders to the map, overwriting any existing items with the same ID
            action.payload.forEach(order =>
                ordersMap.set(order.ID, order)
            );

            // return the values of the map as the new state
            return Array.from(ordersMap.values())
        }
    }
})

export const { addOrders } = OrdersSlices.actions
export default OrdersSlices.reducer