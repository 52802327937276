import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    orders: false,
    messages: false,
    user: false,
    gerencias: false,
    pricelist: false,
    clientlist: false,
}

export const DownloadElementsSlices = createSlice({
    name: 'downloadElements',
    //userdata-gerencias-pricelist-clientlist-orders-messages
    initialState,
    reducers: {
        addDownloadElement: (state, action) => {
            return { ...state, ...action.payload }
        },
        resetDownloadElement: (state) => {
            return initialState
        }
    }
})

export const { addDownloadElement, resetDownloadElement } = DownloadElementsSlices.actions
export default DownloadElementsSlices.reducer