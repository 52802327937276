import { createSlice } from "@reduxjs/toolkit"

export const SapStateSlices = createSlice({
    name: 'sapState',
    initialState: 'Conectando',
    reducers: {
        addSapState: (state, action) => {
            return action.payload
        }
    }
})

export const {addSapState} = SapStateSlices.actions
export default SapStateSlices.reducer