import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useGeneral } from "../../context/generalContext"
import { collection, onSnapshot, where, query, doc, getDoc, getDocs } from 'firebase/firestore';
import { addUser } from '../../store/userdata/UserSlices';
import { addPrice } from '../../store/prices/PriceSlices';
import { addClients } from '../../store/clients/ClientSlices';
import { modalClose } from '../../store/openModal/OpenModalSlices';
import { addGerencias } from '../../store/gerencias/GerenciasSlices';
import { addOrders } from '../../store/orderData/OrdersSlices';
import { addMessagesUIDs } from '../../store/messages/MessagesUIDsSlices';
import { addPriceSync } from '../../store/prices/PriceSyncSlices';
import { addClientSync } from '../../store/clients/ClientSyncSlices';
import { addDownloadElement, resetDownloadElement } from '../../store/indicators/DownloadElementsSlices';
import { addClientInfo } from '../../store/clientInfo/ClientInfoSlices';
import { addPoductInfo } from '../../store/productInfo/ProductInfoSlices';
import { addSapConnected } from '../../store/indicators/SapConnectedSlices';
import { addNewMessageIndi } from '../../store/indicators/NewMessageIndiSlices';
import { addMessages } from '../../store/messages/MessagesSlices';
import { addCondiciones } from '../../store/condicionesDePago/CondicionesDePagoSlices';
import { addPendantOrder } from '../../store/indicators/PendantOrderSlices';
import { addSapState } from '../../store/indicators/SapStateSlices';
import { addUserList } from '../../store/adminPanel/UserListSlices';
import { addTestIndicator, clearTestIndicator } from '../../store/indicators/TestIndicatorSlices';
import { ToastContainer } from 'react-toastify';
import { OrderDetail, Pedidos } from '../order/index';
import { User } from '../user/index'
import { Precios } from '../pricelist/index'
import { Clientes, PerfilClient } from '../client/index'
import { SidebarUser, Header, Loading } from './index'
import { db, Getuserdata, getDocumentById, Getgerenciaid, getDocsByIds, Conect, SLlogout, Adddata, FilterArraySellers, getIdVendedores, Getgerencia } from '../../data/index'
import { ToastError } from '../tools';
import { Ayuda } from '../ajustes/Ayuda';
import { Administracion } from '../ajustes/Administracion';
import { useAuth } from '../../context';
import { addRefMessages } from '../../store/messages/RefMessageSlices';
import { setVendedores } from '../../store/gerencias/vendedoresSlices';
import { UserList } from '../ajustes';
import { List } from '../ajustes/List';
import { addGerenTotal } from '../../store/gerencias/TotalGerenSlice';


const Notificaciones = lazy(() => import('../notification/notificaciones'))

/* pantalla general, carga la seccion seleccionada */
export const Sections = () => {

    const dispatch = useDispatch()
    const userData = useSelector((state) => state.user)
    //console.log(userData)
    const gerencias = useSelector((state) => state.gerencias)
    const fullOrders = useSelector((state) => state.orders)
    const messagesUIDs = useSelector((state) => state.messagesUIDs)
    const sapConnected = useSelector((state) => state.sapConnected)
    //init=0 ok=6 userdata-gerencias-pricelist-clientlist-orders-messages
    const downloadElements = useSelector((state) => state.downloadElements)
    //obj con msjs
    const [messageData, setMessageData] = useState({})
    const [loginUpdated, setLoginUpdated] = useState(false)

    const { datacred, Sl, formatDate, pedidosCollectionFirebase, mensajesCollectionFirebase } = useGeneral()

    const [subListsGerencias, setSubListsGerencias] = useState([])
    const { logout } = useAuth();
    const userdata = Getuserdata()

    //cierra el modal, limpia el dato del reducer y vuelve atras en el registro de url
    const closeSection = () => {
        dispatch(modalClose())
        window.history.back();
    }

    ///////////// FETCH DATA \\\\\\\\\\\\\\\
    //trae los toda la data al iniciar
    const fetchData = async () => {
        // gerencias
        await fetchGerencias()
        //lista de precios
        await fetchPrice()
        //IdVendedores de firebase
        await fetchIdVendedores()
        //condiciones de pago
        //await fetchCondicionesDePago()
    }

    //trae las gerencias y la guarda en rdx
    const fetchGerencias = async () => {
        try {
            const gerenciasIds = await Getgerenciaid(userData)
           

            dispatch(addGerencias(gerenciasIds))

            dispatch(addDownloadElement({ gerencias: true }))
            const gerenTotal = await Getgerencia()

            // console.log(gerenTotal)
            dispatch(addGerenTotal(gerenTotal))
                    
        } catch (error) {
            console.log(error)
        }
    }

    const fetchIdVendedores = async () => {
        try {
            const vendedores = await getIdVendedores();
            
            dispatch(setVendedores(vendedores))

        } catch (error) {
            console.log(error)
        }
    }

    //trae la lista de precios y la guarda en rdx
    //PREVENTA	GERENTE
    // 1- SI    NO	Aparece para todos los usuarios del portal sin importar el stock y precio
    // 2- NO	NO	No aparece para nadie sin importar el stock y precio
    // 3- SI	SI	Aparece para todos los usuarios del portal sin importar el stock y precio
    // 4- NO	SI	Aparece solo a gerentes sin importar el stock y precio

    const fetchPrice = async () => {

        try {
            const priceDoc = await getDocumentById('Lista_Precios', 'QYX6AhGKfrdbBFqSLsOk')
            
            let priceListFilter = []

            // 4 => solo gerentes
            if (userData.Permiso !== 'Vendedor') {
                priceListFilter = [
                    ...priceListFilter,
                    ...priceDoc.Precios.filter(
                        pr => (
                            // 1-3 => para todos
                            ((pr.Exclusivo_Gerencias === 'Y')
                                && (['Y', 'N'].includes(pr.PreVenta)))
                        )
                    )
                ]
            }

            // 1-3 => para todos
            priceListFilter = [
                ...priceListFilter,
                ...(priceDoc.Precios.filter(
                    pr => (
                        ((pr.PreVenta === 'Y')
                            && (['Y', 'N'].includes(pr.Exclusivo_Gerencias)))
                    )
                ))
            ]

            dispatch(addPriceSync(priceDoc.UltimaSincronizacion.toDate().toLocaleDateString()))
            dispatch(addPrice(priceListFilter))
            dispatch(addDownloadElement({ pricelist: true }))

        } catch (error) {
            console.log(error)
        }

    }

    // const fetchCondicionesDePago = async () => {
    //     try {
    //         const condPagoDoc = await getDocumentById('Condiciones_de_pago', 'Fm8tPRrCqbsXVD5eqGW7')

    //         dispatch(addCondiciones(condPagoDoc?.Condiciones))

    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // combina la lista de direcciones con la de clientes
    const joinClientsAdresses = (clients, directions) => {
        let joined = clients.map(cli => {

            let matchDir = directions.filter(dir => dir.CardCode === cli.Cliente_ID)

            if (matchDir.length > 0) {

                return { ...cli, Direcciones_Extra: matchDir }
            }
            return cli
        })

        return joined
    }

    //guarda los uids[] de todos los pedidos en rdx
    const fetchMessageUIDS = async () => {
        let uids = []

        if (userData.Permiso !== 'Administracion') {
            fullOrders.map((ord) => {
                if (ord.UID) {
                    uids.push(ord.UID)
                }
            })
        }
        dispatch(addMessagesUIDs([... new Set(uids)]))
    }

    /////////////////////////////////////////
    const clientsLastSyncro = (clientdoc) => {
        let lastDate = 0

        clientdoc.map((doc) => {
            if (doc.FechaActualizacion > lastDate) { lastDate = doc.FechaActualizacion }
        })

        if (lastDate !== 0) {
            return lastDate.toDate().toLocaleDateString()
        } else {
            return 0
        }
    }
    //////////////////////////////

    const checkSapTest = (CompanyDB) => {
        if (CompanyDB === 'TEST3') {

            dispatch(addTestIndicator({ sap: true }))
        }
    }

    // conectar a SAP
    const conectToSap = async () => {
        if (userData.Sap && datacred) {

            dispatch(addSapState('Conectando'))
            try {
                //TESTING: para produccion =>"datacred.CompanyDB" / testing => "datacred.CompanyDB_Test"
                let status = await Conect(datacred.CompanyDB, datacred.UserName, datacred.Password, datacred.BaseURL, Sl, checkSapTest)
                //let status = await Conect(datacred.CompanyDB_Test, datacred.UserName, datacred.Password, datacred.BaseURL, Sl, checkSapTest)

                if (status === 200) {
                    dispatch(addSapState('SAP'))
                    dispatch(addSapConnected(true))
                } else {
                    ToastError('Fallo en la conexion con SAP')
                    dispatch(addSapState('Desconectado'))
                }
            } catch (error) {
                ToastError('Fallo en la conexion con SAP')
                console.log(error)
            }
        }
    }

    const splitGerencias = () => {
        //tamaño maximo de las sublistas para que funcione "in"
        let sublistSize = 10;
        //cantidad de sublistas que se generaran
        let numSublists = Math.ceil(gerencias.length / sublistSize)
        //guarda el total de las ordenes
        let sublists = []

        for (let i = 0; i < numSublists; i++) {
            sublists.push(gerencias.slice(i * sublistSize, (i + 1) * sublistSize))
        }

        return sublists
    }

    ////////////////////////////////////////////////////
    /////////////// CLIENTS FETCH \\\\\\\\\\\\\\\\\\\\
    //CLIENTES fetch y union de direcciones
    useEffect(() => {
        let isMounted = true
        let clientDoc = []
        
        const fetchClients = async () => {
            try {
               
                if (userData.Permiso !== 'Vendedor'){
                    // gets clientes y direcciones
                    clientDoc = await getDocsByIds('Lista_Clientes', gerencias)
                }else{
                    // Solo trae los datos de sus clientes si es vendedor
                    clientDoc = await FilterArraySellers(gerencias, userData)
                }
                
                let directionsDoc = await getDocsByIds('Lista_Direcciones', gerencias)
                if (isMounted && clientDoc) {
                    // armado de arrays
                    let clientsListBefore = clientDoc?.flatMap(objeto => objeto.Clientes)
                    let clientsList = clientsListBefore.filter(objeto => typeof objeto.CUIT === 'string')
                    let directionsList = directionsDoc?.flatMap(objeto => objeto.Direcciones)

                    // rdx
                    dispatch(addClientSync(clientsLastSyncro(clientDoc)))
                    dispatch(addClients(joinClientsAdresses(clientsList, directionsList)))
                    dispatch(addDownloadElement({ clientlist: true }))
                }
            } catch (error) {
                if (isMounted) {
                    console.log(error)
                }
            }
        }
        if (downloadElements.gerencias) {
            fetchClients()
        }

        return () => {
            isMounted = false
        }
    }, [gerencias, downloadElements.gerencias])

    // /******* obtencion de ORDENES ******* */
    //guarda un estado con las gerencias divididas en listas de 10
    useEffect(() => {
        setSubListsGerencias(splitGerencias())
    }, [gerencias])


    //////////////////////////////////////////////////////
    //////////// condiciones de pago SNAPSHOT ////////////
    useEffect(() => {

        const condPagoDocRef = doc(db, 'Condiciones_de_pago', 'Fm8tPRrCqbsXVD5eqGW7')

        const unsubscribe = onSnapshot(condPagoDocRef, (docSnapshot) => {
            const condPagoData = docSnapshot.data()
            dispatch(addCondiciones(condPagoData?.Condiciones))
        })

        return () => {
            if (unsubscribe) {
                unsubscribe()
            }
        }
    }, [])


    ////////////////////////////////////////////////////
    /////////////// ORDERS SNAPSHOT \\\\\\\\\\\\\\\\\\\\
    // funcion que escucha en vivo los cambios de la coleccion
    useEffect(() => {
        const unsub = []
        let orders = []

        subListsGerencias.forEach((sublist) => {
            const queryRef = query(collection(db, pedidosCollectionFirebase), where("SlpCode", "in", sublist))

            const sub = onSnapshot(queryRef, (snapshot) => {
                orders.push(...snapshot.docs.map((doc) => doc.data()))

                dispatch(addOrders(orders))
                dispatch(addDownloadElement({ orders: true }))
            })
            unsub.push(sub)
        })

        return () => {
            unsub.forEach(sub => sub())
        }
    }, [subListsGerencias])

    //////////////////////////////////////////
    //////// NOTIFICATIONS SNAPSHOT \\\\\\\\\\
    //useffect con return implicito
    useEffect(() => {
        const unsubscribe = [];
        let messages = {};

        if (messagesUIDs.length !== 0) {
            // if (Object.keys(messagesUIDs).length >0) {
            messagesUIDs.forEach((docId) => {
                const docRef = doc(db, mensajesCollectionFirebase, docId);
                const sub = onSnapshot(docRef, (snapshot) => {
                    messages = { ...messages, ...snapshot.data() };

                    setMessageData(messages);
                });

                unsubscribe.push(sub);
            });
        }

        dispatch(addDownloadElement({ messages: true }))

        return () => {
            unsubscribe.forEach((sub) => sub());
        };
    }, [messagesUIDs]);


    const checkNewMessages = () => {
        let newMessage = false

        if (messageData) {

            Object.keys(messageData).map((key) => {
                Object.keys(messageData[key].Mensaje).map(m => {
                    if (!messageData[key].Mensaje[m].Leido
                        && messageData[key].Mensaje[m].User !== userData.Nombre + " " + userData.Apellido) {
                        newMessage = true
                    }
                })
            })

        }
        dispatch(addNewMessageIndi(newMessage))
    }

    useEffect(() => {
        checkNewMessages()
    }, [messageData])

    /********************************** */

    // formatea los mensajes y los guarda en redux
    useEffect(() => {
        if (downloadElements.messages && Object.keys(messageData).length > 0) {
            dispatch(addMessages(formatNotifications(messageData)))

        }
    }, [messageData])
    // /*************************/

    //recibe el paquete de notificaciones y le cambia la fecha de timestamp a string
    const formatNotifications = (notifications) => {
        const formattedNotifications = {}

        // 1 - entra a cada orden
        for (const key in notifications) {
            const notification = notifications[key]
            const formattedMessage = {}

            //  const formattedCreatedDate = formatDate(message.Fecha)
            // 2 - entra a cada mensaje
            for (const messageKey in notification.Mensaje) {
                const message = notification.Mensaje[messageKey]
                const formattedDate = formatDate(message.Fecha)

                // 3 - formatea la fecha del mensaje
                formattedMessage[messageKey] = {
                    ...message,
                    Fecha: formattedDate,
                }
            }
            // 4 - formatea la fecha del paquete y guarda el mensaje
            const formattedNotification = {
                ...notification,
                FechaDeCreacion: formatDate(notification.FechaDeCreacion),
                UltimoMensajeFecha: formatDate(notification.UltimoMensajeFecha),
                Mensaje: formattedMessage,
            }
            formattedNotifications[key] = formattedNotification
        }
        return formattedNotifications

    }

    // Ejecuta la lista de precios y las gerencias
    useEffect(() => {
        if (userData.Apellido) {
            conectToSap()
            fetchData()
        }
    }, [userData]);

    // llamados en orden 
    //despues de traer los pedidos trae los uids de los mensajes
    useEffect(() => {
        if (fullOrders.length > 0) {
            try {
                fetchMessageUIDS()
            } catch (error) {
                console.log(error)
            } finally {

                // agrega el punto de notificacion de pedido pendiente
                fullOrders.map(o => {
                    if (o.UID !== userData.UID) {
                        if (Object.values(o).includes("Pendiente"))

                            dispatch(addPendantOrder(true))
                        return
                    }
                })
            }
        }
    }, [fullOrders])


    //Guarda los datos de usuario en redux
    useEffect(() => {
        if (userdata.Apellido) {

            dispatch(addUser({
                ...userdata,
                lastLogin: userdata.lastLogin ? userdata.lastLogin.toDate().toLocaleDateString() : ''
            }))

            dispatch(addDownloadElement({ user: true }))
        }
    }, [userdata])


    const serviceLayerLost = () => {
        SLlogout(datacred.BaseURL, Sl)
        dispatch(addSapState('Desconectado'))
        dispatch(addSapConnected(false))
        //desconectar app
        logout()
        alert('Conexion con sap perdida, recargue la pagina para volver a conectar')
    }

    /**** SAP TIMEOUT ALERT */
    useEffect(() => {
        if (sapConnected) {
            const timer = setTimeout(() => serviceLayerLost(), 1700000);

            return () => clearTimeout(timer);
        }
    }, [sapConnected]);

    // actualiza la ultima fecha de logueo (una vez por sesion o al actualizar la pag)
    useEffect(() => {
        const updateLogin = async () => {
            if (!loginUpdated && userData.UID) {
                const docRef = query(doc(db, 'Usuarios', userData.UID))

                try {
                    await Adddata(docRef, { lastLogin: new Date() })
                    setLoginUpdated(true)
                } catch (error) {
                    console.log('error al actualizar last login', error)
                }
            }
        }

        updateLogin()
    }, [loginUpdated, userData.UID])

    useEffect(() => {
        if (pedidosCollectionFirebase === "Pedidos_Test") {
            dispatch(addTestIndicator({ pedidos: true }))
        }
        if (mensajesCollectionFirebase === "mensajes_Test") {
            dispatch(addTestIndicator({ mensajes: true }))
        }
    }, [])


    /*************** tomada de UserList ************** */
    useEffect(() => {
        // TODO: pasar a snapshot para mejor respuesta si se piensa utilizar mucho
        const handleGetUsers = async () => {
            let list = []

            const docs = await getDocs(collection(db, "Usuarios"))

            docs.forEach((doc) => {
                // console.log(typeof doc.data().lastLogin === 'object')
                let lastlog = '0'
                if (typeof doc.data().lastLogin === 'object') {
                    lastlog = formatDate(doc.data().lastLogin)
                }

                list.push(
                    {
                        ...doc.data(),
                        lastLogin: lastlog
                    }
                )
            })
            list.sort()

            dispatch(addUserList(list))
        }

        handleGetUsers()

    }, [])

    /*********** ARREGLO DE MODAL **********/
    //se cierra al ir hacia atras con el navegador
    useEffect(() => {

        const handlePopState = () => {
            dispatch(modalClose())
        }

        window.addEventListener('popstate', handlePopState)

        return () => {
            window.removeEventListener('popstate', handlePopState)
        }
    }, [])

    const cleanRdx = () => {
        //limpia rdx
        dispatch(addGerencias([]))
        dispatch(addPrice([]))
        dispatch(addUser({}))
        dispatch(addOrders([]))
        dispatch(addClients([]))
        dispatch(addMessagesUIDs([]))
        dispatch(addClientInfo({}))
        dispatch(addPoductInfo({}))
        dispatch(addPriceSync(0))
        dispatch(addClientSync(0))
        dispatch(addSapConnected(false))
        dispatch(addNewMessageIndi(false))
        dispatch(addMessages({}))
        dispatch(resetDownloadElement())
        dispatch(addCondiciones([]))
        dispatch(addPendantOrder(false))
        dispatch(addUserList([]))
        dispatch(clearTestIndicator())
        dispatch(modalClose())
        dispatch(addSapState('Conectando'))
        dispatch(addRefMessages({}))
        dispatch( setVendedores([]))
        dispatch(addUserList())
    }


    // CLEANUP
    useEffect(() => {
        return () => {
            cleanRdx()
        }
    }, [])


    return (
        <>
            <Header conectToSap={conectToSap} />

            <div className='d-flex usersContainer position-relative'>

                <SidebarUser cleanRdx={cleanRdx} />

                <div id='sectionCont' className='px-4 sectionContainer overflow-auto'>

                    <ToastContainer />

                    <Routes>

                        <Route path="/" element={<Navigate to="users" />} />

                        <Route path='users' element={<User closeSection={closeSection} />} />

                        <Route path='orders' element={<Pedidos closeSection={closeSection} />} />

                        {
                            fullOrders.length > 0
                                ?
                                <Route path='orders/*' element={<OrderDetail closeSection={closeSection} />} />
                                :
                                <Route path='orders/*' element={<Loading />} />
                        }

                        <Route path='clients' element={<Clientes closeSection={closeSection} />} />

                        <Route path='/clients/*' element={<PerfilClient closeSection={closeSection} />} />

                        <Route path='pricelist' element={<Precios closeSection={closeSection} />} />

                        <Route path='notifications' element={
                            <Suspense fallback={<div>Loading</div>} >
                                <Notificaciones closeSection={closeSection} />
                            </Suspense>
                        } />

                        <Route path='help' element={<Ayuda />} />
                        <Route path='Admin' element={<Administracion/>} />
                    </Routes>

                </div>
            </div>
        </>
    )
}

