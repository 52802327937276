
export const Loading = () => {
  return (
    <div className='d-flex justify-content-center align-items-center height100vh'>
      
      <span className="loaderIndi loaderIndiBig"></span>
    </div>

  )
}
