import { useDownloadExcel } from 'react-export-table-to-excel';
import xlsIco from '../../assets/images/xlsxIco.png'

export const ExportTable = (tablereference, section) => {

    const { onDownload } = useDownloadExcel({
        currentTableRef: tablereference.tablereference.current,
        filename: `Lista de ${section}`,
        sheet: "Lista"
    });

    // alerta de confirmacion
    const exportConfirmation = () => {
        if (window.confirm("Confirmar descarga en Excel")) {
            onDownload()
        }
    }


    return (

        <img
            src={xlsIco}
            className='icon30px cursorPointer btnCardHead'
            onClick={exportConfirmation}
        />
    )
}
