import { useDispatch, useSelector } from "react-redux"
import { TableTks } from "../tools/TableTks"
import { Loading } from "../ui"
import { Adddata, db, getDocumentById } from "../../data"
import { doc, getDoc } from "firebase/firestore"
import { addCondiciones } from "../../store/condicionesDePago/CondicionesDePagoSlices"
import { useEffect, useState } from "react"


export const CondDePago = () => {

    const pymntGroups = useSelector(state => state.condicionesDePago)
    // console.log(pymntGroups)
    const [updating, setUpdating] = useState(false)

    const dispatch = useDispatch()


    const handleChangePymntGroup = async (data, value) => {

        setUpdating(true)

        const updatedArray = pymntGroups.map((element) => {
            if (element.GroupNum === data.GroupNum) {
                // Actualizar el elemento específico
                return { ...element, Habilitado: value };
            }
            return element;
        });

        const docRef = doc(db, 'Condiciones_de_pago', 'Fm8tPRrCqbsXVD5eqGW7')

        await Adddata(docRef, {'Condiciones':updatedArray})
    }


    useEffect(() => {
        setUpdating(false)
    }, [pymntGroups])





    return (
        <>
            <h4 className='separar my-4'>
                <b className='text-uppercase'>Habilitar / Deshabilitar Con. de Pago</b>
            </h4>

            <TableTks section='CONFIGURACION' handleChange={handleChangePymntGroup} updating={updating}/>


        </>
    )
}
