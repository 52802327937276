import {createSlice} from '@reduxjs/toolkit'

export const PriceSyncSlices = createSlice({
    name: 'priceSync',
    initialState:0,
    reducers: {
        addPriceSync: (state, action) => {
            return action.payload
        },
    }
})

export const {addPriceSync} = PriceSyncSlices.actions
export default PriceSyncSlices.reducer