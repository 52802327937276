import {createSlice} from '@reduxjs/toolkit'

const initialState = ""

export const OpenDetailSlices = createSlice({
    name: 'openDetail',
    initialState,
    reducers: {
        //recibe id del detail a abrir
        detailOpen: (state, action) => {

            if(action.payload === state) {
                return ''
            } else {
                return action.payload
            }
        },


    }
})

export const {detailOpen} = OpenDetailSlices.actions
export default OpenDetailSlices.reducer