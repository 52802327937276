import { OrderForm } from './index';
import { CardHead, Loading } from '../ui/index';
import {  useSelector } from 'react-redux';


export const CreateOrder = ({ closeSection, section }) => {

    const downloading = useSelector((state) => state.downloadElements)

    return (
        <>
            <div className="modalBase">

                <div className="clrBgLightGray width50Modal mt-a maxH100 overflow-auto">
                    {/* // Encabezado  */}
                    <CardHead section={section + ' PEDIDO'} modal={true} closeSection={closeSection} />

                    {
                        Object.values(downloading).includes(false)
                            ?
                            <Loading/>
                            :
                            <OrderForm section={section} closeSection={closeSection}  />
                    }

                </div>

            </div>
        </>
    )
}
