import { createContext, useContext, useState, useEffect } from 'react';
import {
    createUserWithEmailAndPassword, signInWithEmailAndPassword,
    onAuthStateChanged, signOut
} from 'firebase/auth';
import { auth } from "../data/index";


// Creamos un contexto 
export const authContext = createContext();

//Funcion almacena los datos que retorne el contexto
export const useAuth = () => {
    const context = useContext(authContext)
    return context
};

//Funcion que proveera al contexto de los datos de logueo
export function AuthProvider({ children }) {

    //usestate guarda los datos de usuario que devuelve la conexion con firebase
    const [user, setUser] = useState(null);

    //Booleano para mostrar un loading si la conexcion se demora
    const [loading, setLoading] = useState(true);

    //Funcion de firebase para crear un usuario
    const signup = async (email, password) => {
        const usCred = await createUserWithEmailAndPassword(auth, email, password);

        return usCred
    };

    //Funcion de firebase para logear
    const signin = async (email, password) => {
        await signInWithEmailAndPassword(auth, email, password);
    };

    // Funcion para cerrar sesion
    const logout = () => {
        signOut(auth);
        //limpia la memoria de sesion, por las dudas
        sessionStorage.clear()
        //limpia el local storage
        localStorage.clear()

    }

    //TODO: hacer que cambie algun dato que no permita el logueo
    const disableUser = (uid, bool) => {
        //auth.updateUser(uid, {disabled: bool})
       // console.log(uid, bool)
    }
    //Extrae los datos de usuario de firebase una vez logueado
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, currentUser => {
            setUser(currentUser);
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    return (
        <authContext.Provider value={{ signup, signin, user, logout, loading, disableUser }}>
            {children}
        </authContext.Provider>
    );

}