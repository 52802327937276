import { useDispatch, useSelector } from "react-redux"
import { addFilter } from "../../store/searchFilter/SearchFilterSlices"
import { useEffect } from "react"
import searchIco from '../../assets/images/searchIco.png'

export const GlobalFilter = ({ section }) => {

    const dispatch = useDispatch()
    const filter = useSelector(state => state.searchFilter)

    // limpia el buscador al inicio
    useEffect(() => {
        if (filter !== '') {
            dispatch(addFilter(''))
        }
    }, [])


    return (
        <div className="searchTable position-relative d-flex ">
            <input
                className='form-control globalFilter '
                placeholder={`Buscar ${section}`}
                value={filter} onChange={(e) => { dispatch(addFilter(e.target.value)) }}
            />

            <img
                className="h-75 position-absolute mx-1 align-self-center transition"
                src={searchIco}
                alt=""
            />
        </div>
    )
}