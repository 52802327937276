import {createSlice} from '@reduxjs/toolkit'

export const TotalGerenSlice = createSlice({
    name: 'gerenciasTotal',
    initialState: [],
    reducers: {
        addGerenTotal: (state, action) => {
            // state.push(action.payload)
            //state[0] = action.payload
            return action.payload
        }
    }
})
export const {addGerenTotal} = TotalGerenSlice.actions
export default TotalGerenSlice.reducer