import {createSlice} from '@reduxjs/toolkit'

export const MessagesSlices = createSlice({
    name: 'messages',
    initialState: {},
    reducers: {
        addMessages: (state, action) => {
            return action.payload
        },

    }
})

export const {addMessages} = MessagesSlices.actions
export default MessagesSlices.reducer