// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { browserSessionPersistence, connectAuthEmulator, getAuth, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import { addDoc, collection, connectFirestoreEmulator, initializeFirestore } from "firebase/firestore";
import { listaPrecios, condiciones, gerencias, listaClientes, usuarios, listaDirecciones, pedidos, mensajes } from "./emulatorData";

// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);

export const db = initializeFirestore(app, {
  experimentalForceLongPolling: true, useFetchStreams: false
});

/************************************************/
//en caso de estar en desarrollo usamos el emulador
// if (process.env.NODE_ENV === 'development') {
// console.log('dev')
//   //puertos
//   connectFirestoreEmulator(db, 'localhost', 8080);
//   // firebaseConfig.databaseURL = 'http://localhost:8080?ns=portal-ventas-92850'; // Puerto del emulador de Realtime Database
//   firebaseConfig.functionsEmulatorURL = 'http://localhost:5001/portal-ventas-92850/us-central1'; // Puerto del emulador de Cloud Functions
//   firebaseConfig.firestoreEmulatorURL = 'http://localhost:8080?ns=portal-ventas-92850'; // Puerto del emulador de Firestore
//   firebaseConfig.hostingEmulatorURL = 'http://localhost:5000'; // Puerto del emulador de Hosting

//   connectAuthEmulator(auth, "http://127.0.0.1:8081");

//   //Carga de datos emulados

//   if (!localStorage.getItem("emulated_data")) {
// console.log('upload')


// // agregar a mano un usuario en autenticator emulator y un usuario en la base con el uid de autenticator como UID y Nombre de Documento 
//     const addEmulatedData = async (coleccion, documentos) => {

//       const refColeccion = collection(db, coleccion)
//       for (const documento of documentos) {
//         await addDoc(refColeccion, documento)
//       }
//     }

//     const colecciones = ['Usuarios', 'Gerencias', 'Condiciones_de_pago', 'Lista_Clientes', 'Lista_Precios','Lista_Direcciones','Pedidos', 'mensajes']
//     const datosColecciones = [usuarios, gerencias, condiciones, listaClientes, listaPrecios, listaDirecciones, pedidos, mensajes]

//     for (let i = 0; i < colecciones.length; i++) {
//       await addEmulatedData(colecciones[i], datosColecciones[i])
//     }
//     localStorage.setItem("emulated_data", true)
//     console.log('colecciones creadas en el emulador.');

//   }
// }
/***************************************************/




setPersistence(auth, browserSessionPersistence)
  .then(() => {
    // return signInWithEmailAndPassword(auth, email, password);
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;

    console.log(errorCode, errorMessage)
  });
