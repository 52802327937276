import { createSlice } from "@reduxjs/toolkit"

export const SapConnectedSlices = createSlice({
    name: 'sapConnected',
    initialState: false,
    reducers: {
        addSapConnected: (state, action) => {
            return action.payload
        }
    }
})

export const {addSapConnected} = SapConnectedSlices.actions
export default SapConnectedSlices.reducer