import { useState } from "react"
import { useSelector } from "react-redux"
import { Adddata, db, updateRevision } from "../../data"
import { doc, getDoc } from "firebase/firestore"
import { useGeneral } from "../../context"
import { ToastSucces } from "../tools"

export const NotificationModal = ({ closeSection ,disableButton, disabledBtn, autorizando}) => {

    const orderInfo = useSelector((state) => state.orderData)
    const userData = useSelector((state) => state.user)
    const [rejecting, setRejecting] = useState(false)

    const [message, setMessage] = useState("")

    const { mensajesCollectionFirebase, pedidosCollectionFirebase } = useGeneral()


    // funcion para disparar la funcion que suba a firebase
    const onRejectMsg = async (e) => {
        const name = e.target.name
        setRejecting(true)
        const docRef = doc(db, mensajesCollectionFirebase, orderInfo.UID)
        const docSnap = await getDoc(docRef)

        //numero de mensaje a agrega
        let msjNumB = docSnap.data() ? docSnap.data()[orderInfo.Num_pedido] : 0
        let msjNum = msjNumB ? Object.keys(msjNumB.Mensaje).length : 0

        //estructura del mensaje
        let newMsg = {
            [orderInfo.Num_pedido]: {
                Vendedor: orderInfo.VendedorApellido,
                UID: orderInfo.UID,
                FechaDeCreacion: new Date(),
                Cliente: orderInfo.Cliente,
                MontoTotal: orderInfo.Total,
                FechaPedido: orderInfo.Fecha,
                NuevoMensaje: true,
                UltimoMensajeFecha: new Date(),
                UltimoMensajeDe: userData.Nombre + " " + userData.Apellido,
                Mensaje: {
                    [msjNum]: {
                        Index: msjNum,
                        Leido: false,
                        Asunto: "Orden Rechazada",
                        Contenido: message,
                        Fecha: new Date(),
                        User: userData.Nombre + " " + userData.Apellido,
                    }
                }
            }
        }

        try {
            await Adddata(docRef, newMsg)
            await updateRevision(pedidosCollectionFirebase, orderInfo.ID, userData.Apellido, name)
        } catch (error) {
            console.log(error)
        } finally {

            setRejecting(false)
            closeSection()
            ToastSucces('Orden Rechazada')
        }
    }


    const handleMessage = ({ target }) => {
        setMessage(target.value)
    }

    return (

        <div
            className="d-flex flex-column p-1"
            name="Rechazado"
        >

            <textarea
                className="p-1"
                placeholder="Mensaje"
                id="rejectMsg"
                rows="3" cols="7"
                value={message}
                onChange={handleMessage}>
            </textarea>

            <button
                name='Rechazado'
                className='btn btnSend textBlack btnOrderStatus mt-3 p-1 w-100 minWMaxContent'
                type='button'
                disabled={message.length < 1 || rejecting  || disabledBtn || autorizando}
                onClick={onRejectMsg}>
                Enviar
            </button>

        </div>
    )
}
