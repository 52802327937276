import React, { useEffect, useState } from 'react'
import editIco from '../../assets/images/editIco.png'
import { TextSwitch } from '../ui/switch'
import { Checkbox, ToastError } from '../tools'
import { Adddata, db } from '../../data'
import { doc } from '@firebase/firestore'
import { useSelector } from 'react-redux'

const orderKeys = ["Nombre", "Apellido", "Cuit", "Permiso", "Gerencia", "AreaDeVentas"]
export const UserModal = ({ subSection, user, handleModalUser, permisos, savingMod, submit, savingAdd }) => {
    const  totalGeren = useSelector((state) => state.totalGeren)
    const userList = useSelector((state) => state.userList);
    //console.log(totalGeren)
    const [modUser, setModUser] = useState({})
    const [geren,setGeren] = useState(totalGeren)
    const [value,setValue] = useState('')
    const [nameGerent, setNameGerent] = useState('-');
    //TODO: campo { Sap: true o false } cuando se seleccione el tipo de permiso
    const isOpenModal = useSelector((state) => state.openModal)

    const handleModifyInput = ({ target: { name, value } }) => {
           setModUser({ ...modUser, [name]: value })
           if (name === 'AreaDeVentas'){
                    setValue(value)
           }
    }
    
    useEffect(() => {
        setGeren(totalGeren)
      }, [totalGeren])

      
    useEffect(() => {
      //console.log(geren)
      const searchValue = () => {
        //console.log(value)
        const encontrado = totalGeren.find((e) => e.AreaDeVentas === value)
        //console.log(encontrado)
        if (encontrado) {
            const encontrarUser = userList.find((e) => e.PID === encontrado.Gerencia && e.Permiso === 'Gerencia');
            if(encontrarUser){
                //console.log(encontrarUser)
                setNameGerent(`${encontrarUser.Nombre} ${encontrarUser.Apellido}`);
            }
        }
    }
      searchValue()
    }, [value])
    
    //console.log(nameGerent)

    return (

        <dialog
            open={isOpenModal.a}
            className='modalBackdrop justify-content-center'
        >
            <form
                onSubmit={(e) => submit(e, modUser, user)}
                //onSubmit={()=>submitUserModification(user,modUser)}
                className='modalContent clrBgLightGray'
            >
                <div className='p-2 clrBgPink d-flex justify-content-between'>
                    {
                        subSection === 'add'
                            ? <h5 className='m-0 fw-bold'>AGREGAR USUARIO</h5>
                            : <h5 className='m-0 fw-bold'>MODIFICAR USUARIO</h5>
                    }
                    <input
                        type='button'
                        className="btn-close centerEnd btnCardHead ms-4 p-0 me-2"
                        onClick={() => handleModalUser(false)}
                    />
                </div>


                <div className='row p-3 gy-4 justify-content-center'>
                    {
                        subSection === 'add'
                        &&

                        <div className="col-12 modalInputs gy-4 position-relative text-center ">
                            <label
                                htmlFor={`usmail`}
                                className='inputLabel inputLabelSmall position-absolute px-1 textSmall textColorGreyL'
                            >
                                Email

                            </label>
                            <input
                                id='usmail'
                                name='Mail'
                                onChange={handleModifyInput}
                                className='form-control inputs '
                                required={subSection === 'add'}
                            />

                        </div>
                    }
                    {
                        orderKeys.map(key => (
                            <div
                                key={key}
                                className="col-6 modalInputs gy-4 position-relative text-center "
                            >
                                <label
                                    htmlFor={`us${key}`}
                                    className='inputLabel inputLabelSmall position-absolute px-1 textSmall textColorGreyL'
                                >
                                    {key}
                                </label>
                                {
                                    key === 'Permiso' ? (
                                        <select
                                            className='w-100 h-100 inputs textColorGreyM permitInput p-1'
                                            name='Permiso'
                                            autoComplete="off"
                                            defaultValue={user.Permiso}
                                            onChange={handleModifyInput}
                                        >
                                            <option value=' '>--seleccionar--</option>
                                            {
                                                permisos.map(ger => (
                                                    <option key={ger} value={ger}>
                                                        {ger}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    ) : key === 'AreaDeVentas' ? (
                                        <select
                                            className='w-100 h-100 inputs textColorGreyM permitInput p-1'
                                            name='AreaDeVentas'
                                            autoComplete="off"
                                            defaultValue={user.AreaDeVentas}
                                            onChange={handleModifyInput}
                                        >
                                            <option value=' '>--seleccionar--</option>
                                            {
                                                geren.map(area => (
                                                    <option key={area.AreaDeVentas} value={area.AreaDeVentas}>
                                                        {area.AreaDeVentas}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    ) :
                                     key === 'Gerencia' ? (
                                        <input
                                                className='w-100 h-100 inputs textColorGreyM permitInput p-1'
                                                name='Gerencia'
                                                autoComplete="off"
                                                defaultValue='-'
                                                disabled
                                                onChange={handleModifyInput}
                                                value={nameGerent || '-'}
                                            >
                                                {/* <option value='-'>--seleccionar--</option>
                                                <option value='Marcelo Chiachio'> Marcelo Chiachio</option>
                                                <option value='Ignacio Guarino'> Ignacio Guarino</option>
                                                <option value='Martin Gonzalez'> Martin Gonzalez</option>
                                                <option value='Veronica Romera'> Veronica Romera</option>
                                                <option value='1-Gerencia'>Otras</option> */}
                                        </input>
                                    ) : 
                                    (
                                        <input
                                            id={`us${key}`}
                                            name={key}
                                            defaultValue={user[key]}
                                            onChange={handleModifyInput}
                                            className='form-control inputs'
                                            required={subSection === 'add'}
                                        />
                                    )
                                }
                            </div>
                        ))
                    }
                    <div className='d-flex pt-2 justify-content-around'>
                        <input
                            value='Cerrar'
                            type='button'
                            className='btn btn-outline-secondary me-1 col-6 text-uppercase fw-bold'
                            onClick={() => handleModalUser(false)}
                            disabled={savingMod}
                        />

                        <button
                            type="submit"
                            className='btn btn-outline ms-1 col-6 btnBlue text-uppercase fw-bold'
                            disabled={savingMod}
                        >
                            {
                                !savingMod || !savingAdd
                                    ?
                                    <span id="submitBtnText" className="">
                                        Guardar
                                    </span>
                                    :
                                    <div id='btnLoading' className="spinner-border spinner-border-sm " role="status" />
                            }
                        </button>
                    </div>

                </div>


            </form>
        </dialog>


    )
}