import { createSlice } from '@reduxjs/toolkit'

const UserSlices = createSlice({
    name: 'user',
    initialState: {},
    // {
    //PID: "",// codigo de permiso
    // Permiso: "",
    // UID: "",
    // Nombre: '',
    // Apellido: '',
    // Telefono: '',
    // Gerencia: '',
    // Cuit: '',
    // Fecha_de_nacimiento: '',
    // Mail: '',
    //},
    reducers: {
        addUser: (state, action) => {

            return action.payload
        }
    }
})

export const { addUser } = UserSlices.actions
export default UserSlices.reducer