import {createSlice} from '@reduxjs/toolkit'

export const DownloadingSlices = createSlice({
    name: 'downloading',
    initialState: false,
    reducers: {
        addDownloading: (state, action) => {
            return action.payload
        }
    }
})

export const {addDownloading} = DownloadingSlices.actions
export default DownloadingSlices.reducer