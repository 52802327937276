import {createSlice} from '@reduxjs/toolkit'

export const LoggedSlices = createSlice({
    name: 'logged',
    initialState: false,
    reducers: {
        addLogged: (state, action) => {
            return action.payload
        }
    }
})

export const {addLogged} = LoggedSlices.actions
export default LoggedSlices.reducer